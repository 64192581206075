<template>
  <div
    class="bottom-panel"
    :class="{
      'bottom-panel_mobile': isMobile,
      'bottom-panel_no-height': noHeight,
    }"
  >
    <div
      :style="fixedStyle"
      class="bottom-panel__content"
    >
      <div class="bottom-panel__container">
        <slot name="container" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomPanel',
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
    noHeight: {
      type: Boolean,
      default: false,
    },
    fixedInParent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const { context } = this.$store.state.rootProps

    return {
      isMobile: context.flavour === 'mobile',
    }
  },
  computed: {
    fixedStyle() {
      if (this.fixed && this.fixedInParent) {
        return 'position: absolute'
      }

      return `position: ${this.fixed ? 'fixed' : 'relative'}`
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'frontend/styles/variables';

.bottom-panel {
  box-sizing: border-box;

  &:not(&_no-height) {
    min-height: 56px;
  }

  &_mobile:not(&_no-height) {
    min-height: 72px;
  }

  &__content {
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    box-sizing: inherit;
    min-height: inherit;
    background-color: $ui-kit-bg-gray-0;
    box-shadow:
      0 2px 4px -1px rgba(0, 0, 0, 0.1),
      0 4px 5px 0 rgba(0, 0, 0, 0.07),
      0 1px 10px 0 rgba(0, 0, 0, 0.06);
  }

  &__container {
    width: 100%;
    box-sizing: inherit;
  }
}
</style>
