import { getQueryParam } from '~/deprecated/functions'
import { LOGIN_LINKS } from '~/deprecated/constants'

export default {
  name: 'ChooseLoginType',
  data() {
    const { context } = this.$store.state.rootProps

    const urls = typeof window !== 'undefined'
      ? {
        medtochkaUrl: new URL(LOGIN_LINKS.medtochka(context)),
        cabinetUrl: new URL(LOGIN_LINKS.cabinet(context)),
      }
      : {}

    return {
      loginPathMedtochka: '',
      loginPathCabinet: '',
      ...urls,
    }
  },
  mounted() {
    const queryParamNext = getQueryParam('next')

    if (queryParamNext) {
      this.medtochkaUrl.searchParams.set('next', queryParamNext)
      this.cabinetUrl.searchParams.set('next', queryParamNext)
    }

    this.loginPathMedtochka = this.medtochkaUrl.href
    this.loginPathCabinet = this.cabinetUrl.href
  },
}
