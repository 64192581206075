<template>
  <VFadeTransition>
    <div
      class="search-dialog"
      data-qa="the_header_search_dialog"
    >
      <VCard class="search-dialog__card">
        <div class="search-dialog__input-container">
          <SearchSite
            class="search-dialog__input px-2 py-3"
            data-qa="the_header_search_site"
            :action="search.action"
            :ajax-action="search.ajaxAction"
            :location="search.location"
            :placeholder="search.placeholder"
            :preview-text="search.previewText"
            @dropdown:mounted="handleDropdownMounted"
            @search-site:submit-form="$emit('search-site:submit-form')"
          >
            <template
              #input-control="{
                inputValue,
                placeholder,
                handleBlur,
                handleFocus,
                handleInput,
                handleKeydownArrowUp,
                handleKeydownArrowDown,
                handleSubmitForm,
              }"
            >
              <VTextField
                color="primary"
                prepend-inner-icon="b-button b-button_text ui-icon-arrow-back"
                clear-icon="b-button b-button_text ui-icon-close-not-a-circle"
                append-inner-icon="b-button b-button_text ui-icon-search"
                variant="outlined"
                density="compact"
                :model-value="inputValue"
                :placeholder="placeholder"
                autofocus
                hide-details
                clearable
                @blur="handleBlur"
                @focus="handleFocus"
                @input="handleInput"
                @keydown.up.prevent="handleKeydownArrowUp"
                @keydown.down.prevent="handleKeydownArrowDown"
                @click:prepend-inner="handleCloseModal"
                @click:clear="handleInput"
                @click:append-inner="handleSubmitForm"
              />
            </template>
          </SearchSite>
        </div>
      </VCard>
    </div>
  </VFadeTransition>
</template>

<script>
import SearchSite from '~/deprecated/components/SearchSite/SearchSite.vue'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export default {
  name: 'TheHeaderSearchDialog',
  components: {
    SearchSite,
  },
  props: {
    search: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['search-site:submit-form', 'search-dialog:hide'],
  data: () => ({
    dropdownElement: null,
  }),
  beforeDestroy() {
    enableBodyScroll(this.dropdownElement)
  },
  methods: {
    handleDropdownMounted(dropdownElement) {
      this.dropdownElement = dropdownElement
      disableBodyScroll(this.dropdownElement)
    },
    handleCloseModal() {
      this.$emit('search-dialog:hide')
      document.activeElement.blur()
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'frontend/styles/variables';

.search-dialog {
  &,
  &__card,
  &__input,
  &__input-container {
    height: 100%;
  }
}
</style>

<style lang="scss">
@import 'frontend/deprecated/blocks/b-button/b-button';

.search-dialog {
  position: fixed;

  .v-application--wrap {
    height: 100%;
    visibility: initial;
  }

  .v-input__slot {
    box-sizing: border-box;
  }

  .v-input__slot fieldset {
    margin: 0;
  }

  .v-input__slot input {
    font: inherit;
  }
}

.search-dialog__input {
  .v-input__append-inner + .v-input__append-inner {
    padding-left: 12px;
  }

  // Хак, который позволяет на IOS не перекрывать клавиатурой результаты поиска
  &.search-site_focused .search-site-dropdown__list::after {
    content: '';
    display: block;
    height: 40vh;
  }

  .search-site__input-container {
    display: flex;
    flex-direction: column;
  }

  .search-site-dropdown {
    display: flex;
    height: 100%;
    padding: 24px 0;
    overflow-y: auto;
    margin-top: 8px;

    &__list {
      width: 100%;
    }

    &__preview-text {
      margin-top: 10vh;
      padding: 0 30px;
      text-align: center;
    }
  }
}
</style>
