<template>
  <v-dialog
    :model-value="value"
    :persistent="persistent"
    transition="dialog-bottom-transition"
    fullscreen
  >
    <v-card>
      <BaseToolbar
        :toolbar-icon="toolbarIcon"
        :title="title"
        @input="$emit('input', $event)"
      />
      <div class="px-2">
        <slot />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseToolbar from '~/deprecated/components/BaseToolbar/BaseToolbar.vue'

export default {
  name: 'FullScreenDialog',
  components: {
    BaseToolbar,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: undefined,
    },
    toolbarIcon: {
      type: String,
      default: undefined,
    },
  },
  emits: ['input'],
}
</script>
