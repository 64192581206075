import Cookie from 'js-cookie'
import { axiosClient } from '~/deprecated/functions'
import { useStore } from 'vuex'

const baseConfig = {
  withCredentials: true,
}

const smsPhoneLoginPath = '/services/sso/phone_login/'
const callCreateRequestPath = '/services/sso/calls/create_request/'
const callFinishRequestPath = '/services/sso/calls/finish_request/'
const logoutMtPath = '/services/sso/logout/'
const logoutPdPath = '/profile/logout/'

/**
 * Сервис для авторизации через МедТочку.
 */
export default {
  /**
     * Запрос отправки СМС кода на предоставленный телефон.
     * @param {string} phone
     * @returns {Promise<AxiosResponse<any>>}
     */
  requestCode(phone) {
    const store = useStore()
    const { context } = store.state.rootProps

    return axiosClient.post(
      `${context.site_mt}${smsPhoneLoginPath}`,
      {
        phone,
      },
      baseConfig,
    )
  },
  /**
     * Запрос на подтверждение номера телефона по коду из СМС.
     * @param {string} phone
     * @param {string} secretCode
     * @returns {Promise<AxiosResponse<any>>}
     */
  confirm(phone, secretCode) {
    const store = useStore()
    const { context } = store.state.rootProps

    return axiosClient.post(
      `${context.site_mt}${smsPhoneLoginPath}`,
      {
        phone,
        secret_code: secretCode,
      },
      baseConfig,
    )
  },
  /**
     * Запрос на вход по коду аутентификации, полученному из ответа на запрос подтверждения номера.
     *
     * @param {Object} options
     * @param {string} options.authCode - код авторизации
     * @param {'ws'|'timer'} options.source - для логов на бэке - место, откуда идет запрос (из сокета, по таймеру)
     * @returns {Promise<AxiosResponse<any>>}
     */
  login({ authCode, source }) {
    return axiosClient.post(
      '/api/medtochka/login/',
      {},
      {
        ...baseConfig,
        params: {
          code: authCode,
          source,
          time: Date.now(), // для логов на бэке
        },
        headers: {
          'X-CSRFToken': Cookie.get('csrftoken'),
        },
      },
    )
  },
  /**
     * Запрос на создание WS для подтверждения номера по звонку
     * @param {string} phone
     * @returns {Promise<AxiosResponse<any>>}
     */
  callCreateRequest(phone) {
    const store = useStore()
    const { context } = store.state.rootProps

    return axiosClient.post(
      `${context.site_mt}${callCreateRequestPath}`,
      {
        phone,
      },
      baseConfig,
    )
  },
  /**
     * Запрос на завершение WS для подтверждения номера по звонку
     * @param {Object} options
     * @param {string} options.phone
     * @param {string} options.uuid
     * @param {'ws'|'timer'} options.source - для логов на бэке - место, откуда идет запрос (из сокета, по таймеру)
     * @returns {Promise<AxiosResponse<any>>}
     */
  callFinishRequest({ phone, uuid, source }) {
    const store = useStore()
    const { context } = store.state.rootProps

    return axiosClient.post(
      `${context.site_mt}${callFinishRequestPath}`,
      {
        phone,
        uuid,
      },
      {
        ...baseConfig,
        params: {
          source,
          time: Date.now(), // для логов на бэке
        },
      },
    )
  },
  /**
     * Проверяет, авторизован ли юзер на МТ
     * @param {boolean} skipGrant - не возвращать authorization_code
     * @returns {Promise<AxiosResponse<any>>}
     */
  pullAuth({ skipGrant = false } = {}) {
    const store = useStore()
    const { context } = store.state.rootProps

    // eslint-disable-next-line no-nested-ternary
    const uuid = context.request.user?.user2?.is_real_patient
      ? context.request.user?.user2?.medtochka_uuid || null
      : !context.request.user.is_authenticated
        ? null
        : undefined

    return axiosClient.post(
      `${context.site_mt}/services/sso/pull_authorization/`,
      {
        uuid,
        skip_grant: skipGrant,
      },
      baseConfig,
    )
  },
  /**
     * Разлогинивает юзера на МТ
     * @param {boolean} onlyOneSession - разлогинить только в текущей сессии
     * @returns {Promise<AxiosResponse<any>>}
     */
  logout({ onlyOneSession = false } = {}) {
    const store = useStore()
    const { context } = store.state.rootProps

    return axiosClient.post(
      `${context.site_mt}${logoutMtPath}`,
      {
        only_one_session: onlyOneSession,
      },
      baseConfig,
    )
  },
  /**
     * Разлогинивает юзера на ПД (временно, пока не закончим всю новую авторизацию через МТ)
     * @returns {Promise<AxiosResponse<any>>}
     */
  logoutPd() {
    return axiosClient.get(logoutPdPath)
  },
  /**
     * Отправляет запрос редиректа на МТ для получения ссылки для авторизации на МТ по запросу с ПД.
     * @returns {Promise<AxiosResponse<any>>}
     */
  redirectToMt() {
    return axiosClient.get('/api/medtochka/redirect/')
  },
  /**
     * Отправляет запрос по полученной ссылке для авторизации на МТ с ПД.
     * @param {string} receivedUrl
     * @returns {Promise<AxiosResponse<any>>}
     */
  loginAfterRedirect(receivedUrl) {
    return axiosClient.post(
      receivedUrl,
      {},
      baseConfig,
    )
  },
}
