import { getGoalsList } from '~/deprecated/functions'

export const SHORTCUT_HOME_SCREEN_YA_GOALS = getGoalsList({
  block: 'shortcutHomeScreen',
  goals: [
    'visibleModal', // Отобразили модалку с инструкцией для установки сайта на главный экран
    'visibleBottomSheet', // Отобразили bottomsheet с предложением установить сайт на главный экран
    'clickOpenModalButton', // Нажали на кнопку "[Как это сделать]"
    'clickCopyToClipboard', // Нажали на кнопку "[Скопировать ссылку]"
    'clickChangeInstruction', // Нажали на кнопку  "У меня нет такой кнопки"
    'closeBottomSheet', // Закрыли bottomsheet на 1 день
    'removeBottomSheet', // Закрыли bottomsheet навсегда
  ],
})

export const MOUNT_SELECTOR = '.vue-shortcut-home-screen-mount'

export const ID_FOR_DIRECT_OPEN_MODAL = 'show-shortcut-home-screen-modal'

export const COOKIE_HIDDEN_KEY = 'hidden-shortcut-home-screen'

/* TIME_DEATH_DAY - время, на которое должен исчезнуть bottomsheet после закрытия */
export const TIME_DEATH_ONE_DAY = 1 // 1 день
export const TIME_DEATH_FOREVER = 365 // навсегда

export const BROWSER_NAMES = {
  safari: 'Safari',
  chrome: 'Chrome',
  yandex: 'Yandex',
  yandexStart: 'YandexStart',
}

export const OS_NAMES = {
  ios: 'iOS',
  android: 'Android',
}
