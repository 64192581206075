
    import ClientRenderer from '~/core/modules/Renderer/ClientRenderer'
    import { createRootComponent } from '~/core/modules/Renderer/functions'

    (async () => {
      const rendererOptions = {"pageDescription":{"isFullPage":true,"componentDescriptionDevices":{"desktop":[{"name":"IndexPage","page":"deprecated-index","selector":"#app"}],"mobile":[{"name":"IndexPage","page":"deprecated-index","selector":"#app","location":"pages/deprecated-index"}]}},"device":"mobile"}
      const { pageDescription: { componentDescriptionDevices }, device } = rendererOptions

      componentDescriptionDevices[device][0].rootComponent = createRootComponent(
        (await import(/* webpackMode: 'eager' */'~/pages/deprecated-index/components/IndexPage/IndexPageMobile.vue')).default,
      )

      await new ClientRenderer('full', rendererOptions).render()
    })()
  