import UserStorage from '~/deprecated/modules/UserStorage'
import resetLoginAttemptStorage from '~/deprecated/blocks/auto-login/functions/resetLoginAttemptStorage'
import {
  BAN_DURATION,
  MAX_LOGIN_ATTEMPT,
  LOGIN_ATTEMPT_STORAGE_NAME,
} from '~/deprecated/blocks/auto-login/constants'

function updateLoginAttemptStorage(uuid) {
  const loginAttemptStorage = new UserStorage({ key: LOGIN_ATTEMPT_STORAGE_NAME })
  const loginAttemptData = loginAttemptStorage.get() || {}

  if (loginAttemptData.uuid !== uuid) {
    resetLoginAttemptStorage(uuid)
    return
  }

  if (typeof loginAttemptData.date === 'string') {
    const dateLastLoginAttempt = new Date(loginAttemptData.date)

    // Если время бана прошло, то сбрасываем количество попыток, дату и блокировку авто-логина
    // Также проверяем, что в хранилище хранится корректная дата, иначе сбрасываем значения
    if (new Date() - dateLastLoginAttempt >= BAN_DURATION || !dateLastLoginAttempt.getTime()) {
      resetLoginAttemptStorage(uuid)
      return
    }

    if (!loginAttemptData.isExceeded) {
      loginAttemptData.isExceeded = true

      loginAttemptStorage.set(loginAttemptData)
    }

    return
  }

  if (typeof loginAttemptData.attempt !== 'number') {
    loginAttemptData.attempt = 0
  }

  loginAttemptData.attempt += 1

  // Если количество попыток больше, то добавляем дату последней попытки и блокируем авто-логин
  if (loginAttemptData.attempt > MAX_LOGIN_ATTEMPT) {
    loginAttemptData.date = new Date().toJSON()
    loginAttemptData.isExceeded = true
  }

  loginAttemptStorage.set(loginAttemptData)
}

export default updateLoginAttemptStorage
