import { isJSON, isUsedLocalStorage } from '~/deprecated/functions'

/**
 * @author Быков Александр(@bykov)
 * */

export default class UserStorage {
  constructor({ key, data }) {
    if (!key) {
      throw new Error('To work with the storage, you must specify the key')
    }

    this.key = key

    if (data && !this.get()) {
      this.set(data)
    }
  }

  get() {
    return UserStorage.get(this.key)
  }

  set(data) {
    UserStorage.set(this.key, data)
  }

  remove() {
    UserStorage.remove(this.key)
  }

  clear() {
    UserStorage.clear()
  }

  static get(key) {
    if (isUsedLocalStorage()) {
      const data = localStorage.getItem(key)

      return data ? JSON.parse(data) : null
    }
  }

  static set(key, data) {
    if (isUsedLocalStorage()) {
      localStorage.setItem(key, !isJSON(data) ? JSON.stringify(data) : data)
    }
  }

  static remove(key) {
    if (isUsedLocalStorage()) {
      localStorage.removeItem(key)
    }
  }

  static clear() {
    if (isUsedLocalStorage()) {
      localStorage.clear()
    }
  }
}
