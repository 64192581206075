<template>
  <VDialog
    v-model="isDialogVisible"
    :fullscreen="isMobile"
    :width="isMobile ? undefined : 456"
  >
    <ModalLayout
      title="Ваша клубная карта"
      class="club-card-info"
      data-qa="club_card_info"
      close-to
      :body-flex-basis-auto="!isMobile"
      @modal:back="handleCloseDialog"
    >
      <div class="pa-10">
        <div class="club-card-info__card-container">
          <div class="w-100">
            <div class="club-card-info__card text-body-1 text-bg-gray-0">
              <div class="d-flex align-center text-h6">
                <img
                  src="/static/_v1/pd/icons/ui-kit/club/club-percent-white.svg"
                  class="mr-2"
                  width="24"
                  height="24"
                  alt="Иконка Процент Клуба"
                >
                Клуб ПроДокторов
              </div>
              <div class="d-flex flex-column align-center">
                <img
                  src="/static/_v1/pd/icons/ui-kit/club/club-check-white.svg"
                  class="mb-2"
                  width="72"
                  height="72"
                  alt="Иконка Галочка Клуба"
                >
                <span
                  class="text-h6 mb-1"
                  data-qa="club_card_info_user_phone"
                >
                  {{ formattedPhone }}
                </span>
                <span class="text-body-2">
                  в клубе до {{ clubData.cardDateEnd }}
                </span>
              </div>
              <div>
                Если возникнут вопросы:
                <br>8 800 707-31-29
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="!isMobile"
          class="mt-6"
        >
          <VBtn
            color="primary"
            data-qa="club_card_info_fixed_close_button"
            block
            flat
            @click="handleCloseDialog"
          >
            Закрыть
          </VBtn>
        </div>
      </div>
      <BottomPanel
        v-if="isMobile"
        fixed
      >
        <template #container>
          <div class="px-2 pt-2 pb-6">
            <VBtn
              color="primary"
              data-qa="club_card_info_fixed_close_button"
              block
              flat
              @click="handleCloseDialog"
            >
              Закрыть
            </VBtn>
          </div>
        </template>
      </BottomPanel>
    </ModalLayout>
  </VDialog>
</template>

<script>
import { getPhoneFormatted } from '~/deprecated/functions'
import { CLUB_CARD_INFO_YA_GOALS } from '~/deprecated/components/ClubCardInfo/constants'
import ManageYandexGoals from '~/deprecated/mixins/ManageYandexGoals'
import ModalLayout from '~/deprecated/components/ModalLayout/ModalLayout.vue'
import BottomPanel from '~/deprecated/components/BottomPanel/BottomPanel.vue'

export default {
  components: {
    BottomPanel,
    ModalLayout,
  },
  mixins: [
    ManageYandexGoals,
  ],
  data() {
    const { context } = this.$store.state.rootProps

    return {
      isDialogVisible: false,
      clubData: {
        isInClub: context.request.user?.is_in_club,
        cardMtLink: context.request.user?.club_card?.mt_link || '',
        cardDateEnd: context.request.user?.club_card?.dt_end || '',
        cardDateStart: context.request.user?.club_card?.dt_start || '',
        cardNextPrice: context.request.user?.next_club_card_price || null,
        email: context.request.user?.club_card_request?.receipt_email || '',
        discountOnServices: context.service_club_discount_percent || null,
      },
      isMobile: context.flavour === 'mobile',
      userPhone: context.request.user?.user2?.mobileuser?.phone,
      yaGoalsList: CLUB_CARD_INFO_YA_GOALS,
    }
  },
  computed: {
    formattedPhone() {
      return getPhoneFormatted(this.userPhone)
    },
  },
  mounted() {
    window.addEventListener('club-card-info:open', ({ detail }) => {
      if (detail) {
        if (detail.clubData) {
          this.clubData = detail.clubData
        }

        if (detail.userPhone) {
          this.userPhone = detail.userPhone
        }
      }

      this.isDialogVisible = true
      this.sendYandexGoal('open')
    })
  },
  methods: {
    handleCloseDialog() {
      this.isDialogVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'frontend/styles/variables';

.club-card-info {
  &__card-container {
    display: flex;

    &::before {
      content: '';
      display: block;
      padding-top: 145%;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    min-height: 360px;
    padding: 16px 24px 8px;
    text-align: center;
    border-radius: 8px;
    background-color: $ui-kit-error;
    background-image: $club-coral-double-radial-gradient;
  }
}
</style>
