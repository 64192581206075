/**
 * @description
 *
 * Диспатчит событие и доп данные на window
 *
 * @param { String } name - название события
 * @param { Any= } detail - данные, передаваемые с событием
 */
function dispatchCustomEventGlobally(name, detail) {
  if (!name || typeof name !== 'string') {
    return
  }

  window.dispatchEvent(new CustomEvent(name, { detail }))
}

export default dispatchCustomEventGlobally
