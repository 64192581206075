<template>
  <VScrollYReverseTransition>
    <VBanner
      v-if="isVisibleBottomSheet"
      class="shortcut-bottom-sheet"
      elevation="24"
    >
      <div class="py-2">
        <VBtn
          icon
          variant="text"
          depressed
          class="shortcut-bottom-sheet__close"
          color="uiKitBgGray60"
          @click="closeBottomSheet"
        >
          <VIcon>
            ui-icon-close-not-a-circle
          </VIcon>
        </VBtn>
        <VImg
          src="/static/_v1/pd/icons/prodoctorov-with-heart.svg"
          class="mb-3"
          height="78"
          width="115"
          contain
        />
        <div class="text-h6 mb-2">
          Добавьте сайт на главный<br>
          экран телефона
        </div>
        <div class="text-body-1 mb-3">
          Врач может понадобиться экстренно,
          пусть ПроДокторов будет под рукой
        </div>
        <VBtn
          block
          depressed
          class="mb-2"
          color="uiKitBgGray40"
          @click="submitBottomSheet"
        >
          Как это сделать
        </VBtn>
        <VBtn
          variant="text"
          block
          depressed
          color="uiKitBgGray40"
          @click="removeBottomSheet"
        >
          Больше не показывать
        </VBtn>
      </div>
    </VBanner>
  </VScrollYReverseTransition>
</template>

<script>
export default {
  name: 'ShortcutHomeScreenBottomSheet',
  props: {
    isVisibleBottomSheet: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['bottom-sheet:close', 'bottom-sheet:remove', 'bottom-sheet:submit'],
  methods: {
    closeBottomSheet() {
      this.$emit('bottom-sheet:close')
    },
    removeBottomSheet() {
      this.$emit('bottom-sheet:remove')
    },
    submitBottomSheet() {
      this.$emit('bottom-sheet:submit')
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'frontend/styles/variables';

.shortcut-bottom-sheet {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 202;
  color: $ui-kit-bg-gray-0;
  background-color: rgba($ui-kit-text-secondary, 0.88) !important;

  &__close {
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 8px;
  }
}
</style>
