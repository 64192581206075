<template>
  <SearchSite
    :action="action"
    :ajax-action="ajaxAction"
    :location="{
      town, district, microdistrict, street, metro,
    }"
    :placeholder="placeholder"
    :preview-text="prompt"
    class="base-search"
    :class="{ 'base-search_desktop': fullMode }"
  >
    <template
      #input-control="{
        isFocused,
        inputValue,
        handleBlur,
        handleFocus,
        handleInput,
        handleKeydownArrowUp,
        handleKeydownArrowDown,
      }"
    >
      <MaterialInput
        ref="inputComponent"
        class="base-search__input"
        type="text"
        autocomplete="off"
        :value="inputValue"
        :borders-has="fullMode ? '1 1 1 1' : '1 0 1 1'"
        :placeholder="placeholder"
        :gray="fullMode && !isFocused"
        required
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        @keydown.native.up.prevent="handleKeydownArrowUp"
        @keydown.native.down.prevent="handleKeydownArrowDown"
      />
    </template>

    <button
      type="submit"
      class="base-search__button b-button b-button_red b-button_big"
      :class="{
        'base-search__button_full': fullMode,
        'b-button_appendable': !fullMode,
      }"
      data-role="none"
      aria-label="Найти"
    >
      <span
        v-if="fullMode"
        class="text-button text-bg-gray-0 ui-icon-search"
      />
      <svg
        v-else
        class="b-icon b-icon-search text-bg-gray-0"
        width="24"
        height="24"
      >
        <use xlink:href="/static/_v1/pd/svg-sprites/global.svg#icon-search" />
      </svg>
    </button>
  </SearchSite>
</template>

<script>
import SearchSite from '~/deprecated/components/SearchSite/SearchSite.vue';
import MaterialInput from '~/deprecated/components/MaterialInput/MaterialInput.vue';

export default {
  // eslint-disable-next-line
    name: 'Search',
  components: {
    SearchSite,
    MaterialInput,
  },
  props: {
    town: {
      type: String,
      default: '',
    },
    ajaxAction: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
    district: {
      type: String,
      default: '',
    },
    microdistrict: {
      type: String,
      default: '',
    },
    street: {
      type: String,
      default: '',
    },
    metro: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    prompt: {
      type: String,
      default: '',
    },
    fullMode: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
@import 'frontend/styles/variables';

.base-search {
  display: flex;
  position: relative;

  &_desktop &__button {
    margin-left: 16px;
    flex: 0 0 56px;
  }

  &_desktop .search-site__input-container {
    position: relative;
  }

  .search-site__input-container {
    flex-grow: 1;
  }

  .search-site-dropdown {
    z-index: 2;
    padding: 24px 0;
    position: absolute;
    border-radius: 5px;
    background: $ui-kit-bg-gray-0;
    width: 100%;
    box-sizing: border-box;
    top: 100%;
    font-size: 16px;
    color: $ui-kit-text;
    user-select: none;

    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.07),
      0 1px 18px rgba(0, 0, 0, 0.06),
      0 3px 5px rgba(0, 0, 0, 0.1);

    &__item {
      padding: 0 24px;
    }

    &__preview-text {
      padding: 0 24px;
    }
  }
}

@mixin background-transition-timing {
  transition-property: background-color;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@mixin reactions($hoverBack, $pressedBack, $fontColor) {
  @include background-transition-timing;

  &:hover {
    background-color: $hoverBack;
    color: $fontColor;
  }

  &:active {
    background-color: $pressedBack;
    color: $fontColor;
  }
}

.b-button {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 40px;
  padding: 0 16px;
  border: none;
  border-radius: 4px;
  background-color: $ui-kit-bg-gray-60;
  color: $ui-kit-text;
  white-space: nowrap;
  text-decoration: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  appearance: none;

  &_red {
    background-color: $ui-kit-secondary;
    color: $ui-kit-bg-gray-0;

    @include reactions(#CE2326, #AB2325, $ui-kit-bg-gray-0);
  }

  &_big {
    height: 56px;
  }

  &_appendable {
    border-radius: 0 4px 4px 0;
  }
}

.b-icon {
  display: inline-block;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}
</style>
