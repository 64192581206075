<template>
  <ShortcutHomeScreenBottomSheet
    :is-visible-bottom-sheet="isVisibleBottomSheet"
    @bottom-sheet:close="closeBottomSheet"
    @bottom-sheet:remove="removeBottomSheet"
    @bottom-sheet:submit="handleClickOpenModal"
  />
  <ShortcutHomeScreenModal
    :user-agent-data="userAgentData"
    :is-visible-modal="isVisibleModal"
    @modal:close="closeModal"
  />
</template>

<script>
import Cookie from 'js-cookie'
import { isBrowserSupported } from '~/deprecated/functions'
import YaGoals from '~/deprecated/modules/YandexGoals'
import { getUserAgentData } from '~/deprecated/components/ShortcutHomeScreen/functions'
import ShortcutHomeScreenModal from '~/deprecated/components/ShortcutHomeScreen/components/ShortcutHomeScreenModal.vue'
import ShortcutHomeScreenBottomSheet from '~/deprecated/components/ShortcutHomeScreen/components/ShortcutHomeScreenBottomSheet.vue'
import {
  COOKIE_HIDDEN_KEY,
  TIME_DEATH_ONE_DAY,
  TIME_DEATH_FOREVER,
  ID_FOR_DIRECT_OPEN_MODAL,
  SHORTCUT_HOME_SCREEN_YA_GOALS,
} from '~/deprecated/components/ShortcutHomeScreen/constants'

export default {
  name: 'ShortcutHomeScreen',
  components: {
    ShortcutHomeScreenModal,
    ShortcutHomeScreenBottomSheet,
  },
  data: () => ({
    isVisibleModal: false,
    isVisibleBottomSheet: false,
    userAgentData: getUserAgentData(),
    instanceYaGoals: new YaGoals(),
  }),
  computed: {
    // Можно ли предлагать пользователю установить сайт на главный экран с инструкцией к конкретному браузеру
    isCanVisibleBottomSheet() {
      const isUnknownThisBrowserOnIOS = this.userAgentData.isIOS && !this.userAgentData.isKnowThisBrowser

      // Если пользователь на IOS открыл неизвестный браузер, то предлагаем ему скопировать ссылку и перейти в Safari
      if (isUnknownThisBrowserOnIOS) {
        return true
      }

      return isBrowserSupported() // если браузер современный, т.е. поддерживается нами
        && this.$store.state.rootProps.context.flavour === 'mobile' // если мобильное устройство
        && (this.userAgentData.isIOS || this.userAgentData.isAndroid) // если IOS или Android
        && this.userAgentData.isKnowThisBrowser // если браузер из определённого нами списка
    },
  },
  mounted() {
    if (!this.isCanVisibleBottomSheet) {
      return
    }

    const isOpenByDirectLink = window.location.hash === `#${ID_FOR_DIRECT_OPEN_MODAL}`

    if (isOpenByDirectLink) {
      this.openModal()
      this.removeHash()

      return
    }

    if (!Cookie.get(COOKIE_HIDDEN_KEY)) {
      this.openBottomSheet()
      Cookie.set(COOKIE_HIDDEN_KEY, true, { expires: TIME_DEATH_ONE_DAY, path: '/' })
    }
  },
  methods: {
    openModal() {
      this.isVisibleModal = true

      this.instanceYaGoals.send({ name: SHORTCUT_HOME_SCREEN_YA_GOALS.visibleModal })
    },
    closeModal() {
      this.isVisibleModal = false

      this.closeBottomSheet(true)
    },
    openBottomSheet() {
      this.isVisibleBottomSheet = true

      this.instanceYaGoals.send({ name: SHORTCUT_HOME_SCREEN_YA_GOALS.visibleBottomSheet })
    },
    closeBottomSheet(isAfterModal = false) {
      this.isVisibleBottomSheet = false

      if (!isAfterModal) {
        this.instanceYaGoals.send({ name: SHORTCUT_HOME_SCREEN_YA_GOALS.closeBottomSheet })
      }
    },
    removeBottomSheet() {
      this.isVisibleBottomSheet = false

      this.instanceYaGoals.send({ name: SHORTCUT_HOME_SCREEN_YA_GOALS.removeBottomSheet })

      Cookie.set(COOKIE_HIDDEN_KEY, true, { expires: TIME_DEATH_FOREVER, path: '/' })
    },
    handleClickOpenModal() {
      this.instanceYaGoals.send({ name: SHORTCUT_HOME_SCREEN_YA_GOALS.clickOpenModalButton })

      this.openModal()
    },
    removeHash() {
      window.history.pushState('', document.title, `${window.location.pathname}${window.location.search}`)
    },
  },
}
</script>
