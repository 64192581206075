<template>
  <VApp>
    <Head>
      <title>
        {{ DEFAULT_TITLE }}
      </title>
      <meta
        property="og:title"
        :content="DEFAULT_TITLE"
      >
      <link
        rel="apple-touch-icon"
        :href="`/static/_v1/pd/favicons/apple_touch_icon/apple-touch-icon.png?v=${iconsVersion}`"
      >
      <link
        v-for="size in appleTouchIconSizes"
        rel="apple-touch-icon"
        :sizes="size"
        :href="`/static/_v1/pd/favicons/apple_touch_icon/favicon_${size}.png?v=${iconsVersion}`"
      >
      <link
        rel="mask-icon"
        sizes="180x180"
        color="#3981f1"
        :href="`/static/_v1/pd/favicons/apple_touch_icon/mask/favicon_180x180.svg?v=${iconsVersion}`"
      >
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        :href="`/static/_v1/pd/favicons/android_chrome/favicon_192x192.png?v=${iconsVersion}`"
      >
      <link
        v-for="size in iconSizes"
        rel="icon"
        type="image/png"
        :sizes="size"
        :href="`/static/_v1/pd/favicons/favicon_${size}.png?v=${iconsVersion}`"
      >
      <link
        rel="shortcut icon"
        type="image/x-icon"
        :href="`/favicon.ico?v=${iconsVersion}`"
      >
      <link
        rel="manifest"
        :href="`/static/_v1/pd/favicons/android_chrome/site.webmanifest?v=${iconsVersion}`"
      >
      <meta
        name="msapplication-TileColor"
        content="#1A5DD0"
      >
      <meta
        name="msapplication-TileImage"
        :content="`/static/_v1/pd/favicons/mstile/favicon_144x144.png?v=${iconsVersion}`"
      >
      <meta
        name="msapplication-config"
        :content="`/static/_v1/pd/favicons/mstile/browserconfig.xml?v=${iconsVersion}`"
      >
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1"
      >
      <meta
        http-equiv="Content-Type"
        content="text/html; charset=UTF-8"
      >
      <meta
        name="format-detection"
        content="telephone=no"
      >
      <link
        rel="dns-prefetch"
        href="https://mc.yandex.ru"
      >
      <link
        rel="preconnect"
        href="https://mc.yandex.ru"
      >
      <link
        rel="canonical"
        :href="`https://prodoctorov.ru${context.request.path}${context.page_num > 1 ? `?page=${context.page_num}` : ''}`"
      >
    </Head>

    <header class="b-the-header">
      <slot name="the-header">
        <TheHeader
          :site-logo="{
            src: '/static/_v1/pd/logos/ui-kit/prodoctorov-logo.svg',
            alt: 'ПроДокторов',
          }"
          :search="{
            useRender: true,
            action: `/${context.town?.translit}/find/`,
            ajaxAction: `/ajax/${context.town?.translit}/find/`,
            location: {
              town: context.town?.translit,
              district: context.district?.translit,
              microdistrict: context.microdistrict?.translit,
              street: context.street?.translit,
              metro: context.metro?.translit,
            },
            placeholder: 'Врачи, клиники, услуги',
            previewText: 'Вы можете искать по фамилии врача, названию клиники, услуге, метро или району…',
          }"
        />
      </slot>

      <form
        v-if="context.request.user?.is_staff"
        data-enhance="false"
        action="/change_flavour/"
        method="post"
      >
        <input
          type="hidden"
          name="csrfmiddlewaretoken"
          :value="`${context.csrf_token || ''}`"
        >
        <button
          type="submit"
          class="b-change-flavour text-body-2"
          data-role="none"
        >
          Настольная версия
        </button>
      </form>
    </header>

    <slot v-bind="$attrs" />

    <VNoSsr>
      <ShortcutHomeScreen />
      <SnackbarNotification />
      <BaseDialog />
      <DraftReminder />
      <ClubCardInfo />
      <SideMenu
        :town="context.town.name"
        town-select-url="/town/"
        :projects="{
          medtochka: {
            logo: '/static/_v1/pd/logos/ui-kit/medtochka-logo.svg',
            description: 'Личный кабинет пациента и медкарта',
            url: 'https://medtochka.ru/',
          },

          protabletky: {
            logo: '/static/_v1/pd/logos/ui-kit/protabletky-logo.svg',
            description: 'Сайт отзывов врачей о лекарствах',
            url: `https://${context.site_pt.domain || 'protabletky.ru'}/`,
          },

          probolezny: {
            logo: '/static/_v1/pd/logos/ui-kit/probolezny-logo.svg',
            description: 'Научно-популярная энциклопедия заболеваний',
            url: `https://${context.site_bl.domain || 'probolezny.ru'}/`,
          },

          medflex: {
            logo: '/static/_v1/pd/logos/ui-kit/medflex-logo.svg',
            description: 'Сервис безопасного обмена медицинскими данными',
            url: 'https://medflex.ru/',
          },

          medlock: {
            logo: '/static/_v1/pd/logos/ui-kit/medlock-logo.svg',
            description: 'Медицинская информационная система',
            url: 'https://medlock.ru/',
          },
        }"
        :personal-area-url="personalAreaUrl"
      />
      <StageAlert v-if="isStage()" />
    </VNoSsr>

    <TheFooterMobile />
  </VApp>
</template>

<script setup lang="ts">
// eslint-disable-next-line import/no-unresolved
import { Head } from '@unhead/vue/components'
import { DEFAULT_TITLE } from '~/layouts/DefaultLayout/constants'
import { useStore } from 'vuex'
import { useHead } from '@unhead/vue'
import TheFooterMobile from '~/components/TheFooter/TheFooterMobile.vue'
import { isStage } from '~/functions'
import SnackbarNotification from '~/deprecated/components/SnackbarNotification/SnackbarNotification.vue'
import SideMenu from '~/deprecated/components/SideMenu/SideMenu.vue'
import ShortcutHomeScreen from '~/deprecated/components/ShortcutHomeScreen/components/ShortcutHomeScreen.vue'
import StageAlert from '~/components/StageAlert/StageAlert.vue'
import DraftReminder from '~/deprecated/components/DraftReminder/DraftReminder.vue'
import BaseDialog from '~/deprecated/components/BaseDialog/BaseDialog.vue'
import ClubCardInfo from '~/deprecated/components/ClubCardInfo/ClubCardInfo.vue'
import TheHeader from '~/deprecated/components/TheHeader/TheHeaderMobile.vue'
// blocks
import autoLogin from '~/deprecated/blocks/auto-login'

defineOptions({
  inheritAttrs: false,
})

autoLogin()

const store = useStore()
const { context } = store.state.rootProps

const appleTouchIconSizes = ['57x57', '60x60', '72x72', '76x76', '114x114', '120x120', '144x144', '152x152', '180x180']
const iconSizes = ['32x32', '64x64', '194x194']
const iconsVersion = 'v0FBeZ0jaG'

const metrikaId = context.metrika_id || '10630678'

useHead({
  script: [
    {
      id: 'script-yandex-metrics',
      innerHTML: `
        (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
        m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)
        })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

        ym(${metrikaId}, "init", {
        triggerEvent: true,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true
        });
      `,
    },
  ],
  noscript: [
    {
      innerHTML: `
        <div>
          <img
            src="https://mc.yandex.ru/watch/${metrikaId}"
            style="position:absolute; left:-9999px;"
            alt=""
          >
        </div>
      `,
    },
  ],
  link: [
    {
      tagPriority: 20,
      rel: 'preload',
      as: 'font',
      type: 'font/woff2',
      crossorigin: 'anonymous',
      href: '/static/_v1/pd/fonts/Inter/Inter-Regular.woff2',
    },
    {
      tagPriority: 20,
      rel: 'preload',
      as: 'font',
      type: 'font/woff2',
      crossorigin: 'anonymous',
      href: '/static/_v1/pd/fonts/Inter/Inter-Medium.woff2',
    },
  ],
})

let personalAreaUrl = ''

if (context.fake_user) {
  if (context.lpu?.owner && context.request.path?.includes('/lpu/')) {
    personalAreaUrl = `https://${context.site_pd?.domain}/profile/lpus/?lpu=${context.lpu?.id}`
  } else if (context.doctor?.owner && context.request.path?.includes('/vrach/')) {
    personalAreaUrl = `https://${context.site_pd?.domain}/profile/doctors/?doctor=${context.doctor?.id}`
  }
} else if (context.request.user?.user2?.usertype === 1) {
  personalAreaUrl = '/api/medtochka/redirect/'
} else if (context.request.user?.user2?.usertype === 2 && context.doctor_bread) {
  personalAreaUrl = `https://${context.site_pd?.domain}/profile/doctors/`
} else if (context.request.user?.user2?.is_lpu) {
  personalAreaUrl = `https://${context.site_pd?.domain}/profile/lpus/`
}
</script>

<style scoped lang="scss">
.b-the-header {
  position: relative;

  &__change-flavour {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 4px;
    text-align: center;
  }
}

.b-change-flavour {
  background: none !important;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  border-bottom: 1px solid #444444 !important;
  cursor: pointer;
  border-radius: 0 !important;
  margin: 0 auto;
  display: block;
  white-space: nowrap;
  box-shadow: none !important;
  width: 130px !important;
  outline: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  text-align: center;
}

.b-change-flavour:hover {
  color: red !important;
  border-bottom-color: red !important;
}
</style>
