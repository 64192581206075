<template>
  <v-dialog
    v-model="isActive"
    :persistent="persistent"
    :max-width="maxWidth"
    :no-click-animation="noClickAnimation"
    class="base-dialog"
  >
    <v-card>
      <v-card-title
        class="text-h6 text-text"
      >
        {{ title }}
      </v-card-title>
      <v-card-text
        class="text-body-1 text-text-secondary"
      >
        {{ text }}
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="closeText"
          :color="confirmText ? 'text' : 'primary'"
          variant="text"
          @click="handleClose"
        >
          {{ closeText }}
        </v-btn>
        <v-btn
          v-if="confirmText"
          variant="text"
          :color="isDanger ? 'secondary' : 'primary'"
          @click="handleConfirm"
        >
          {{ confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EVENTS as DIALOG_EVENTS, MAX_WIDTH } from '~/deprecated/components/BaseDialog/constants'

export default {
  name: 'BaseDialog',
  data() {
    return {
      maxWidth: MAX_WIDTH,
      isActive: false,
      isDanger: false,
      persistent: false,
      noClickAnimation: false,
      title: '',
      text: '',
      closeText: '',
      confirmText: '',
      beforeClose: undefined,
      beforeConfirm: undefined,
    }
  },
  mounted() {
    window.addEventListener(DIALOG_EVENTS.open, e => this.openDialog(e.detail))
    window.addEventListener(DIALOG_EVENTS.close, e => this.closeDialog(e.detail))
  },
  methods: {
    openDialog({
      isDanger,
      persistent,
      noClickAnimation,
      title,
      text,
      closeText,
      confirmText,
      beforeClose,
      beforeConfirm,
      maxWidth,
    }) {
      this.isActive = true
      this.isDanger = isDanger
      this.persistent = persistent
      this.noClickAnimation = noClickAnimation
      this.title = title
      this.text = text
      this.closeText = closeText
      this.confirmText = confirmText
      this.beforeClose = beforeClose
      this.beforeConfirm = beforeConfirm
      this.maxWidth = maxWidth || MAX_WIDTH
    },
    closeDialog() {
      this.isActive = false
      this.isDanger = false
      this.persistent = false
      this.noClickAnimation = false
      this.title = ''
      this.text = ''
      this.closeText = ''
      this.confirmText = ''
      this.beforeClose = undefined
      this.beforeConfirm = undefined
    },
    handleClose() {
      if (this.beforeClose) {
        this.beforeClose()
      }

      this.closeDialog()
    },
    handleConfirm() {
      if (this.beforeConfirm) {
        this.beforeConfirm()
      }

      this.closeDialog()
    },
  },
}
</script>

<style lang="scss" scoped>
.base-dialog {
  // выше SideMenu
  z-index: 10002;
}
</style>
