import isTouch from '~/deprecated/functions/isTouch'

let scrollPosition = 0
let enabled = false

function isEnabled() {
  return enabled
}

function enable() {
  const body = document.querySelector('body')
  const hasTouch = isTouch()

  if (!hasTouch) {
    document.body.style.paddingRight = `${window.innerWidth - document.documentElement.clientWidth}px`
    document.documentElement.style.overflow = 'hidden'

    enabled = true

    return
  }

  scrollPosition = window.pageYOffset
  body.style.position = 'fixed'
  body.style.top = `-${scrollPosition}px`
  body.style.width = '100%'

  enabled = true
}

function disable() {
  const body = document.querySelector('body')
  const hasTouch = isTouch()

  if (!hasTouch) {
    document.body.style.paddingRight = ''
    document.documentElement.style.overflow = ''

    enabled = false

    return
  }

  body.style.removeProperty('position')
  body.style.removeProperty('top')
  body.style.removeProperty('width')
  window.scrollTo(0, scrollPosition)

  enabled = false
}

/**
 * todo: необходимо избавиться от текущей утилиты и использовать библиотеку 'body-scroll-lock',
 * т.к. текущая реализация не всегда корректно работает.
 *
 * UPD: 'body-scroll-lock' тоже некорректно работает: на декстопе не пропадает scrollbar,
 * т.к. либа скрывает его на `<body>`, а у нас скорлл работает на `<html>`. Но даже с этим недостатком
 * предпочтительнее использовать данную либу (данная проблемы была вынесена в тех.долг для исправления).
 *
 * @deprecated
 * */

const scrollLock = {
  enable,
  disable,
  isEnabled,
}

export default scrollLock
