<template>
  <div class="stage-alert">
    Stage
  </div>
</template>

<style scoped lang="scss">
@import 'frontend/styles/variables';

.stage-alert {
  z-index: 9999999;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 8px 16px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 10px;
  font-weight: 600;
  font-style: italic;
  background-color: $ui-kit-error;
  color: $ui-kit-bg-gray-0;
  opacity: 0.96;
  pointer-events: none;
}

@media screen and (max-width: 800px) {
  .stage-alert {
    top: 0;
    bottom: initial;
    font-size: 0;
    padding: 4px;
  }
}
</style>
