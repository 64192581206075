import requestTimeout from '~/deprecated/functions/requestTimeout'

/**
 * Содержит requestStop для каждого анимируемого элемента
 * (необходимо для того, чтобы была возможность остановить анимацию у определённых элементов,
 * т.к. анимация может быть запущена у нескольких элементов одновременно)
 *
 * Структура: { [element]: [requestStop] }
 * */
const requestIdMap = new Map()

/**
 * Анимированно сворачивает/разворачивает элемент
 *
 * @param {Object} options
 * @param {Element} options.element - Элемент у которого будет анимироваться высота.
 * @param {Boolean} options.isExpand - Флaг, указывающий на то - нужно свернуть или развернуть элемент.
 * @param {Number|undefined} options.duration - Продолжительность анимации в миллисекундах.
 * @param {Function|undefined} options.hookBeforeAnimateStart - Callback-функция, срабатывающая перед началом анимации.
 * @param {Function|undefined} options.hookAfterAnimateEnd - Callback-функция, срабатывающая после конца анимации.
 * */
function animateCollapse({
  element,
  isExpand,
  duration = 200,
  hookAfterAnimateEnd = () => {},
  hookBeforeAnimateStart = () => {},
}) {
  const baseOptions = {
    element,
    duration,
    hookAfterAnimateEnd: () => hookAfterAnimateEnd({ isExpand }),
    hookBeforeAnimateStart: () => hookBeforeAnimateStart({ isExpand }),
  }

  if (isExpand) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    slideDown(baseOptions)
  } else {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    slideUp(baseOptions)
  }
}

/**
* Останавливает анимацию у элемента
* */
function stopAnimate(element) {
  if (requestIdMap.has(element)) {
    requestIdMap.get(element)()
  }
}

/**
 * Увеличивает высоту
 *
 * @param {Object} options
 * @param {Element} options.element - Элемент у которого будет увеличена высота.
 * @param {Number} options.currentHeight - Текущая высота элемента.
 * @param {Number} options.progress - Коэффициент (от 0 до 1) на который будет увеличена высота.
 * */
function incrementHeight({
  element, currentHeight, progress,
}) {
  const progressHeight = progress * (element.scrollHeight - currentHeight)

  element.style.height = `${currentHeight + progressHeight}px`
}

/**
 * Уменьшает высоту
 *
 * @param {Object} options
 * @param {Element} options.element - Элемент у которого будет уменьшена высота.
 * @param {Number} options.currentHeight - Текущая высота элемента.
 * @param {Number} options.progress - Коэффициент (от 0 до 1) на который будет уменьшена высота.
 * */
function decrementHeight({
  element, currentHeight, progress,
}) {
  const progressHeight = progress * currentHeight

  element.style.height = `${currentHeight - progressHeight}px`
  element.style.overflow = 'hidden'
}

/**
 * Анимированно увеличивает высоту элемента
 * от `0` (или от `element.style.height`) до `element.scrollHeight`
 *
 * @param {Object} options
 * @param {Element} options.element - Элемент, который будет анимированно разворачиваться.
 * @param {Number} options.duration - Продолжительность анимации в миллисекундах.
 * @param {Function|undefined} options.hookBeforeAnimateStart - Callback-функция, срабатывающая в начале анимации.
 * @param {Function|undefined} options.hookAfterAnimateEnd - Callback-функция, срабатывающая после конца анимации.
 * */
function slideDown({
  element,
  duration = 200,
  hookBeforeAnimateStart = () => {},
  hookAfterAnimateEnd = () => {},
}) {
  stopAnimate(element)
  hookBeforeAnimateStart()

  const start = performance.now()
  const currentHeight = parseFloat(element.style.height) || 0

  let requestStop

  element.style.display = 'block'

  requestStop = requestTimeout(function loop(time) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const progress = getProgress({
      time, start, duration,
    })

    if (progress < 1) {
      incrementHeight({
        element, currentHeight, progress,
      })

      requestStop = requestTimeout(loop)

      requestIdMap.set(element, requestStop)
    }

    if (progress === 1) {
      element.style.height = 'auto'
      element.style.overflow = 'initial'
      element.style.display = 'block'

      hookAfterAnimateEnd()
    }
  })

  requestIdMap.set(element, requestStop)
}

/**
 * Анимированно уменьшает высоту элемента
 * от `element.scrollHeight` (или от `element.style.height`) до 0
 *
 * @param {Object} options
 * @param {Element} options.element - Элемент, который будет анимированно сворачиваться.
 * @param {Number} options.duration - Продолжительность анимации в миллисекундах.
 * @param {Function|undefined} options.hookBeforeAnimateStart - Callback-функция, срабатывающая в начале анимации.
 * @param {Function|undefined} options.hookAfterAnimateEnd - Callback-функция, срабатывающая после конца анимации.
 * */
function slideUp({
  element,
  duration = 200,
  hookAfterAnimateEnd = () => {},
  hookBeforeAnimateStart = () => {},
}) {
  stopAnimate(element)
  hookBeforeAnimateStart()

  const start = performance.now()
  const currentHeight = parseFloat(element.style.height) || element.scrollHeight

  let requestStop

  requestStop = requestTimeout(function loop(time) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const progress = getProgress({
      time, start, duration,
    })

    if (progress < 1) {
      decrementHeight({
        element, currentHeight, progress,
      })

      requestStop = requestTimeout(loop)

      requestIdMap.set(element, requestStop)
    }

    if (progress === 1) {
      element.style.height = ''
      element.style.overflow = ''
      element.style.display = ''

      hookAfterAnimateEnd()
    }
  })

  requestIdMap.set(element, requestStop)
}

/**
 * Функция возвращает значение от 0 до 1,
 * определяющее прогресс анимации
 *
 * @returns {Number}
 * */
function getProgress({
  time, start, duration,
}) {
  const runtime = time - start
  const relativeProgress = runtime / duration

  return Math.min(relativeProgress, 1)
}

export default animateCollapse
