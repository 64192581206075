import UserStorage from '~/deprecated/modules/UserStorage'
import { LOGIN_ATTEMPT_STORAGE_NAME } from '~/deprecated/blocks/auto-login/constants'

function isExceededLoginAttempt() {
  const { isExceeded } = UserStorage.get(LOGIN_ATTEMPT_STORAGE_NAME) || {}

  return isExceeded
}

export default isExceededLoginAttempt
