<template>
  <v-snackbar
    v-model="isActive"
    v-bind="{ ...snackbarSettings }"
    class="snackbar-notification"
    :class="{ 'snackbar-notification_dialog': upper }"
    :timeout="snackbarTimeout"
    color="uiKitText"
  >
    <span
      class="text-body-2"
    >
      {{ message }}
    </span>

    <template
      v-if="actionText"
      #actions
    >
      <v-btn
        color="uiKitButtonTextSnackbars"
        class="fix-medotvet"
        variant="text"
        @click="handleAction"
      >
        {{ actionText }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { TIMEOUT, EVENTS } from './constants'

export default {
  name: 'SnackbarNotification',
  data() {
    return {
      isActive: false,
      message: '',
      actionText: '',
      upper: false,
      snackbarSettings: {},
      handleAction: () => {},
    }
  },
  computed: {
    snackbarTimeout() {
      return this.actionText ? TIMEOUT.withAction : TIMEOUT.withoutAction
    },
  },
  mounted() {
    window.addEventListener(EVENTS.open, e => this.openSnackbar(e.detail))
    window.addEventListener(EVENTS.close, e => this.closeSnackbar(e.detail))
  },
  methods: {
    async openSnackbar({
      message,
      upper = false,
      actionText,
      snackbarSettings,
      handleAction,
    }) {
      /**
             * Если snackbar уже был открыт, то сначала закрываем его, и после анимации закрытия открываем
             * (это добавляет эффект обновления snackbar при множественном открытии)
             * */
      if (this.isActive) {
        await this.closeSnackbar()
      }

      this.isActive = true
      this.message = message
      this.actionText = actionText
      this.upper = upper
      this.snackbarSettings = snackbarSettings
      this.handleAction = handleAction
    },
    async closeSnackbar() {
      this.isActive = false

      await this.waitCloseAnimation()
      this.resetSnackbar()
    },
    resetSnackbar() {
      Object.assign(this.$data, this.$options.data.apply(this))
    },
    waitCloseAnimation() {
      return new Promise(resolve => {
        setTimeout(resolve, 150)
      })
    },
  },
}
</script>

<style lang="scss">
[data-snackbar-notification] {
  .snackbar-notification {
    inset: 0;
    height: initial;

    // выше BaseDialog и SideMenu
    z-index: 10003;

    .v-snack__wrapper {
      min-width: 304px;
      width: auto;
    }

    &.snackbar-notification_dialog .v-snack__wrapper {
      bottom: 56px;
    }
  }
}
</style>
