<template>
  <div
    class="text-field"
    :class="{
      'text-field_active': isFocused,
      'text-field_gray': gray,
    }"
  >
    <div class="text-field__body">
      <fieldset
        class="text-field__fieldset"
        :class="{
          'text-field__fieldset_no-top': !borders.top,
          'text-field__fieldset_no-right': !borders.right,
          'text-field__fieldset_no-bottom': !borders.bottom,
          'text-field__fieldset_no-left': !borders.left,
        }"
      />
      <div class="text-field__input-slot">
        <input
          ref="input"
          class="text-field__input text-body-1"
          type="text"
          :value="internalValue"
          :placeholder="placeholder"
          data-role="none"
          @focus="handleFocus"
          @blur="handleBlur"
          @change="handleChange"
          @input="handleInput"
        >
      </div>
      <slot name="append" />
    </div>
    <slot name="append-outer" />
  </div>
</template>

<script>
export default {
  name: 'MaterialInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    bordersHas: {
      type: String,
      default: '',
    },
    gray: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lazyValue: this.value,
      isFocused: false,
      borders: {
        top: true,
        left: true,
        right: true,
        bottom: true,
      },
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.lazyValue
      },
      set(val) {
        this.lazyValue = val
        this.$emit('input', val)
      },
    },
  },
  watch: {
    value(val) {
      this.lazyValue = val
    },
    bordersHas: {
      immediate: true,
      handler(val) {
        if (val) {
          const bordersArr = val.split(' ')

          if (bordersArr.length !== 4) {
            throw new Error('Должно быть указано 4 значения')
          }

          bordersArr.every(border => {
            if (Number.isNaN(Number(border))) {
              throw new Error('Должны быть указаны цифры')
            }

            return true
          })

          this.borders.top = bordersArr[0] !== '0'
          this.borders.right = bordersArr[1] !== '0'
          this.borders.bottom = bordersArr[2] !== '0'
          this.borders.left = bordersArr[3] !== '0'
        }
      },
    },
  },
  methods: {
    handleInput($event) {
      this.internalValue = $event.target.value
    },
    handleBlur($event) {
      this.$emit('blur', $event)
      this.isFocused = false
    },
    handleFocus($event) {
      this.$emit('focus', $event)
      this.isFocused = true
    },
    handleChange($event) {
      this.$emit('change', $event)
    },
  },
}
</script>

<style scoped lang="scss">
@import 'frontend/styles/variables';

.text-field {
  $block: &;

  display: flex;

  &_gray {
    #{$block}__body {
      background-color: $ui-kit-bg-gray-40;
      border-radius: 4px;
    }

    #{$block}__input::placeholder {
      color: $ui-kit-text-info;
    }

    #{$block}__fieldset {
      border: none;
    }
  }

  &__body {
    height: 56px;
    flex: 1 1 auto;
    position: relative;
    display: flex;
    align-items: center;
    background-color: white;
  }

  &__fieldset {
    margin-inline: 0;
    padding-block: 0;
    padding-inline: 0;
    min-inline-size: min-content;
    padding: 0;
    position: absolute;
    inset: 0;
    border-radius: 4px;
    border: 1px solid $ui-kit-text-info;
    pointer-events: none;

    &_no-top {
      border-top: none;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

    &_no-right {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &_no-bottom {
      border-bottom: none;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &_no-left {
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &:hover &__fieldset {
    border-color: $ui-kit-text;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  &_active &__fieldset,
  &_active:hover &__fieldset {
    border-width: 2px;
    border-color: $ui-kit-primary;
  }

  &__input-slot {
    padding: 0 16px;
    flex: 1 1 auto;
  }

  &__input {
    background-color: transparent;
    border-style: none;
    border-radius: 0;
    outline: none;
    flex: 1 1 auto;
    line-height: 20px;
    padding: 8px 0;
    max-width: 100%;
    min-width: 0;
    width: 100%;
  }

  &_active &__input {
    caret-color: $ui-kit-primary;
  }
}
</style>
