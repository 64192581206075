import axios from 'axios'
import dispatchCustomEventGlobally from '~/deprecated/functions/dispatchCustomEventGlobally'

/**
 * @description
 *
 * Для отправки данных POST запросом можно использовать serializeObject() из utils, чтобы привести их к querystring формату,
 * т.к. по дефолту axios отправляет JSON Payloads, а бэк по дефолту парсит FormData
 * */

const instance = axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
})

instance.interceptors.request.use(config => {
  dispatchCustomEventGlobally('axios:request', config)
  return config
})

export default instance
