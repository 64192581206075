export default function getPhoneFormatted(phone) {
  if (typeof phone !== 'string') {
    return phone
  }

  const phoneFormatted = phone.replace(/\D+/g, '')

  if (phoneFormatted.length === 10 || phoneFormatted.length === 11) {
    return phoneFormatted.replace(/\d?(\d{3})(\d{3})(\d{2})(\d{2})/, '+7 $1 $2-$3-$4')
  }

  return phone
}
