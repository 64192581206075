<template>
  <footer class="b-footer pt-8 pb-4">
    <div class="text-body-1 text-text px-2 mb-6">
      <a
        href="/"
        class="d-inline-flex mb-8"
      >
        <img
          alt="Логотип ПроДокторов"
          src="/static/_v1/pd/logos/ui-kit/prodoctorov-mono-logo.svg"
          width="158"
          height="24"
        >
      </a>
      <br>

      <a
        class="b-footer__link mb-6"
        href="https://medrocket.ru/"
      >О компании</a>
      <br>
      <a
        class="b-footer__link mb-6"
        href="/info/about-us/"
      >О проекте</a>
      <br>
      <a
        class="b-footer__link mb-6"
        href="/info/document/"
      >Редакция и юрлицо</a>
      <br>
      <a
        class="b-footer__link mb-6"
        href="/info/pressa/"
      >О нас говорят</a>
      <br>
      <a
        class="b-footer__link mb-6"
        href="/info/conf/"
      >Выступления</a>
      <br>
      <a
        class="b-footer__link mb-6"
        href="/info/legal-case/"
      >Битва за отзывы</a>
      <br>
      <a
        class="b-footer__link mb-6"
        href="/info/rate-fraud-free/"
      >Отзывы без обмана</a>
      <br>
      <a
        class="b-footer__link mb-6"
        href="/info/vacancies/"
      >Вакансии</a>
      <br>
      <a
        class="b-footer__link mb-6"
        href="/info/socials/"
      >Соцсети</a>
      <br>
      <a
        class="b-footer__link"
        href="/info/contacts/"
      >Контакты</a>
    </div>
    <div
      id="footer-expansion-panels"
      class="b-expansion-panels text-body-1 text-text mb-6"
    >
      <div class="b-expansion-panels__panel">
        <div class="b-expansion-panels__head">
          <div class="d-flex align-center pl-2 py-5">
            <span
              class="ui-icon-more-projects mr-8"
              aria-hidden="true"
            />
            Наши проекты
          </div>
        </div>

        <div class="b-expansion-panels__body">
          <div class="px-2 py-4">
            <PrgButton
              target="_self"
              class="mb-6"
              url="https://award.prodoctorov.ru/"
              :button-attrs="{ class: 'b-footer__link' }"
            >
              Премия ПроДокторов
            </PrgButton>
            <PrgButton
              target="_self"
              class="mb-6"
              url="https://blog.medrocket.ru/"
              :button-attrs="{ class: 'b-footer__link' }"
            >
              Блог МедРокет
            </PrgButton>
            <PrgButton
              target="_self"
              class="mb-6"
              :url="`https://${context.site_pt.domain}/`"
              :button-attrs="{ class: 'b-footer__link' }"
            >
              ПроТаблетки
            </PrgButton>
            <PrgButton
              target="_self"
              class="mb-6"
              :url="`https://${context.site_bl.domain}/`"
              :button-attrs="{ class: 'b-footer__link' }"
            >
              ПроБолезни
            </PrgButton>
            <PrgButton
              target="_self"
              class="mb-6"
              url="https://medflex.ru/"
              :button-attrs="{ class: 'b-footer__link' }"
            >
              МедФлекс
            </PrgButton>
            <PrgButton
              target="_self"
              class="mb-6"
              url="https://medtochka.ru/"
              :button-attrs="{ class: 'b-footer__link' }"
            >
              МедТочка
            </PrgButton>
            <PrgButton
              target="_self"
              class="mb-6"
              url="https://medlock.ru/"
              :button-attrs="{ class: 'b-footer__link' }"
            >
              МедЛок
            </PrgButton>
          </div>
        </div>
      </div>
      <div class="b-expansion-panels__panel">
        <div class="b-expansion-panels__head">
          <div class="d-flex align-center pl-2 py-5">
            <span
              class="ui-icon-circle-help mr-8"
              aria-hidden="true"
            />
            Справка
          </div>
        </div>
        <div class="b-expansion-panels__body">
          <div class="px-2 py-4">
            <PrgButton
              target="_self"
              class="mb-6"
              url="https://help.prodoctorov.ru/"
              :button-attrs="{ class: 'b-footer__link' }"
            >
              Помощь
            </PrgButton>

            <a
              class="b-footer__link mb-6"
              href="/info/patient-about/"
            >Пациенту</a>
            <br>
            <a
              class="b-footer__link mb-6"
              href="/info/doctor-about/"
            >Врачу</a>
            <br>
            <a
              class="b-footer__link"
              href="/info/lpu-about/"
            >Клинике</a>
          </div>
        </div>
      </div>
      <div class="b-expansion-panels__panel">
        <div class="b-expansion-panels__head">
          <div class="d-flex align-center pl-2 py-5">
            <span
              class="ui-icon-map-full mr-8"
              aria-hidden="true"
            />
            Карта сайта
          </div>
        </div>
        <div class="b-expansion-panels__body">
          <div class="px-2 py-4">
            <a
              class="b-footer__link mb-6"
              href="/town/"
            >Города</a>
            <br>
            <a
              class="b-footer__link mb-6"
              href="/regions/"
            >Регионы</a>
            <br>
            <a
              class="b-footer__link mb-6"
              href="/vrach/"
            >Врачи</a>
            <br>
            <a
              class="b-footer__link mb-6"
              href="/lpu/"
            >Клиники</a>
            <br>
            <a
              class="b-footer__link"
              href="/services/"
            >Услуги</a>
          </div>
        </div>
      </div>
      <div class="b-expansion-panels__panel">
        <div class="b-expansion-panels__head">
          <div class="d-flex align-center pl-2 py-5">
            <span
              class="ui-icon-hand-shaking mr-8"
              aria-hidden="true"
            />
            Условия использования
          </div>
        </div>
        <div class="b-expansion-panels__body">
          <div class="px-2 py-4">
            <a
              class="b-footer__link mb-6"
              href="/info/terms-of-use/"
            >Пользовательское соглашение</a>
            <br>
            <a
              class="b-footer__link mb-6"
              href="/info/privacy-policy/"
            >Обработка персональных данных</a>
            <br>
            <a
              class="b-footer__link mb-6"
              href="/info/publishing-policy/"
            >Публикация материалов</a>
            <br>
            <a
              class="b-footer__link mb-6"
              href="/info/authors-protection/"
            >Условия программы защиты авторов отзывов</a>
            <br>
            <a
              class="b-footer__link"
              href="/info/rate-fraud-free-rules/"
            >Условия программы "Отзывы без обмана"</a>
          </div>
        </div>
      </div>
    </div>
    <div
      class="b-divider my-6"
      aria-hidden="true"
    />

    <div class="text-body-2 text-text-secondary px-2">
      <div class="mb-4">
        © 2011-{{ currentYear }}, ПроДокторов
      </div>
      <div class="mb-4">
        Зарегистрировано Федеральной службой по надзору в сфере связи,
        информационных технологий и массовых коммуникаций (Роскомнадзор).
        Свидетельство Эл № ФС77-54527 от 21.06.2013 г.
      </div>
      <div class="mb-4">
        Учредитель — ООО «МедРейтинг». Адрес редакции:
        {{ context.company.physical_address.postal_code }},
        {{ context.company.physical_address.region }},
        г. {{ context.company.physical_address.city }},
        ул. {{ context.company.physical_address.street }},
        д. {{ context.company.physical_address.building }},
        офис {{ context.company.physical_address.office }}.
      </div>
      <div class="text-no-wrap d-flex mb-1">
        <span class="mr-1">
          Телефон:
        </span>
        <a
          href="tel:+74953749356"
          class="text-text-secondary"
        >
          {{ getPhoneFormatted(context.company.phone) }}
        </a>
      </div>
      <div class="text-no-wrap d-flex mb-4">
        <span class="mr-1">
          E-mail:
        </span>
        <a
          href="mailto:help@medrocket.ru"
          class="text-text-secondary"
        >
          {{ context.company.email }}
        </a>
      </div>
      <div class="d-flex mb-4">
        <img
          src="/static/_v1/pd/icons/plus-18.svg"
          alt="18+"
          width="38"
          height="38"
          class="mr-4"
        >

        <PrgButton
          target="_self"
          class="d-inline-flex"
          url="/static/_v1/pd/documents/pdf/Единый_реестр_российских_программ.pdf"
          :button-attrs="{ 'aria-label': 'Единый реестр российских программ' }"
        >
          <img
            src="/static/_v1/pd/logos/ru-reestr-programs.png"
            alt="Единый реестр российских программ"
            width="48"
            height="57"
          >
        </PrgButton>
      </div>
      <div class="mb-4">
        Информация, представленная на сайте, не может быть использована для постановки диагноза,
        назначения лечения и не заменяет прием врача.
      </div>
      <div class="mb-4">
        ОГРН 1122311003760
      </div>
      <div>
        Главный редактор — C.Р. Федосов.
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import PrgButton from '~/components/PrgButton/PrgButton.vue'
import { getPhoneFormatted } from '~/deprecated/functions'
import ExpansionPanels from '~/deprecated/modules/ExpansionPanels'
import { onMounted } from 'vue'

const store = useStore()
const { context } = store.state.rootProps

const currentYear = new Date().getFullYear()

onMounted(() => {
  const expansionPanelElement = document.getElementById('footer-expansion-panels')

  if (expansionPanelElement) {
    new ExpansionPanels({
      target: expansionPanelElement,
    })
  }
})
</script>

<style scoped lang="scss">
@import 'frontend/styles/variables';

.b-footer {
  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }

  padding: 40px 0 64px;
  color: $ui-kit-text;
  background-color: $ui-kit-bg-secondary;
  clear: both;

  &__row {
    display: flex;
    justify-content: space-between;
    margin: 0 -12px;
  }

  &__column {
    padding: 0 12px;
  }

  &__info-registration {
    flex-basis: 476px;
  }

  &__info-address {
    flex-basis: 370px;
  }

  &__info-additional-item {
    position: relative;
    display: flex;

    &:not(:first-child) {
      padding-left: 28px;

      &::before {
        content: '';
        position: absolute;
        top: calc(50% - 2px);
        left: 13px;
        width: 2px;
        height: 2px;
        border-radius: 100%;
        background-color: currentColor;
      }
    }
  }

  &__warning {
    max-width: 714px;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
</style>

<style lang="scss">
.b-footer {
  &__link {
    color: inherit;
    display: inline-flex;
    text-decoration-line: underline;

    &:hover {
      text-decoration-line: none;
    }
  }
}
</style>
