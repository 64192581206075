/**
 * Тормозящий (throttling) декоратор.
 * Возвращает обёртку, передавая вызов в `callback` не более одного раза в `delay` миллисекунд.
 *
 * Inspired by https://learn.javascript.ru/call-apply-decorators
 *
 * @param {Function} callback Функция, которую будет вызывать декоратор
 * @param {number} delay Число миллисекунд, указывающее задержку для вызовов `callback`
 * @param {object} options Опции настройки декоратора.
 * @param {boolean} options.strictMode Строгий режим, при котором по окончании задержки `delay` не будет произведён вызов `callback`
 * @return {Function}
 * */

function throttle(callback, delay, { strictMode = false } = {}) {
  let isThrottled = false
  let savedArgs
  let savedThis

  function wrapper(...args) {
    if (isThrottled) {
      savedArgs = args
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      savedThis = this

      return
    }

    callback.apply(this, args)

    isThrottled = true

    setTimeout(() => {
      isThrottled = false

      if (savedArgs && !strictMode) {
        wrapper.apply(savedThis, savedArgs)
        savedArgs = null
        savedThis = null
      }
    }, delay)
  }

  return wrapper
}

export default throttle
