import { getGoalsList } from '~/deprecated/functions'

export const DRAFT_REMINDER_YANDEX_GOALS = getGoalsList({
  block: 'draftReminder',
  goals: [
    // Уведомление о черновике отзыва (все)
    'show', // Показ
    'notNow', // Нажатие на "Не сейчас"
    'goToReview', // Нажатие на "К отзыву"
  ],
})

export const MOUNT_SELECTOR = '.vue-draft-reminder-mount'
export const STORAGE_KEY = 'DraftReminder'
export const HOURS_BETWEEN_DISPLAYS = 12
export const MINUTES_BEFORE_FIRST_DISPLAY = 60
export const MAX_NUMBER_OF_DISPLAYS = 2
export const AJAX_URL_IS_VISIBLE = '/is-show-unfinished-draft/'
export const MODULE_NAME = 'DraftReminder'
export const REVIEW_OBJECT_TYPES = {
  doctor: 'doctor',
  lpu: 'lpu',
}
