<template>
  <DefaultLayoutMobile>
    <Head>
      <meta
        name="description"
        content="
          Крупнейший сайт отзывов о врачах и клиниках России.
          Более трёх миллионов честных отзывов пациентов.
          Поделитесь своим мнением о лечении - внесите вклад в народный рейтинг!
        "
      >
    </Head>

    <template #the-header>
      <TheHeader
        :site-logo="{
          src: '/static/_v1/pd/logos/ui-kit/prodoctorov-logo.svg',
          alt: 'ПроДокторов',
        }"
        :search="{
          useRender: false,
        }"
      />
    </template>

    <div class="b-intro">
      <div class="px-4">
        <div class="b-intro__header">
          <h1 class="b-intro__title mt-0">
            Сайт отзывов о врачах №1 в&nbsp;России
          </h1>
          <div class="text-body-2 text-text-secondary">
            по количеству отзывов,<br>посетителей и страниц врачей<br>(исследование РБК, сентябрь 2019)
          </div>
        </div>
        <div class="b-intro__body">
          <div class="b-intro__search-container">
            <div
              class="b-search-v2 search-placeholder"
              data-enhance="false"
            >
              <TheSearch
                :action="`/${context.town.translit}/find/`"
                :ajax-action="`/ajax/${context.town.translit}/find/`"
                :town="context.town.translit"
                :district="context.district?.translit"
                :microdistrict="context.microdistrict?.translit"
                :street="context.street?.translit"
                :metro="context.metro?.translit"
                placeholder="Врачи, клиники, услуги"
                prompt="Вы можете искать по фамилии врача, названию клиники, услуге, метро или району…"
              />
            </div>
          </div>
          <div class="b-intro__stat">
            <div class="b-intro__stat-reviews-item">
              <div class="b-intro__stat-reviews-item-num text-text">
                {{ formatNumber(context.stat.rates_total || '0') }}
              </div>
              <div class="text-body-1 text-text-secondary">
                реальных отзывов
              </div>
            </div>
            <div class="b-intro__stats-container">
              <div class="b-intro__stat-item">
                <div class="b-intro__stat-item-img b-intro__stat-item-img_type_appointment" />
                <div class="b-intro__stat-item-content">
                  <div class="b-intro__stat-item-num text-text">
                    {{ formatNumber(context.appointments_total) }}
                  </div>
                  <div class="text-body-1 text-text-secondary">
                    записей
                  </div>
                </div>
              </div>

              <div class="b-intro__stat-item">
                <div class="b-intro__stat-item-img b-intro__stat-item-img_type_doctor" />
                <div class="b-intro__stat-item-content">
                  <div class="b-intro__stat-item-num text-text">
                    {{ formatNumber(context.stat.doctors_total || '0') }}
                  </div>
                  <div class="text-body-1 text-text-secondary">
                    врачей
                  </div>
                </div>
              </div>
              <div class="b-intro__stat-item">
                <div class="b-intro__stat-item-img b-intro__stat-item-img_type_lpu" />
                <div class="b-intro__stat-item-content">
                  <div class="b-intro__stat-item-num text-text">
                    {{ formatNumber(context.stat.lpu_total || '0') }}
                  </div>
                  <div class="text-body-1 text-text-secondary">
                    клиник
                  </div>
                </div>
              </div>
              <div class="b-intro__stat-item">
                <div class="b-intro__stat-item-img b-intro__stat-item-img_type_users" />
                <div class="b-intro__stat-item-content">
                  <div class="b-intro__stat-item-num text-text">
                    {{ formatNumber(context.stat.prodoctorov_attendance) }}
                  </div>
                  <div class="text-body-1 text-text-secondary">
                    посетителей в месяц
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="b-pd-nav py-10">
      <div class="px-4">
        <div class="b-pd-nav__header">
          <h2 class="b-pd-nav__heading">
            <span class="b-pd-nav__heading-text">Популярное</span>
            <a
              href="/town/"
              data-role="none"
              class="b-pd-nav__heading-town-link"
              :data-translit="context.town.translit || 'moskva'"
              data-effect="mag-popup_anim_vertical"
              :data-url="`/${context.town.translit || 'moskva'}/`"
            >
              {{ context.town.name }}
            </a>
          </h2>
        </div>
        <div class="b-pd-nav__content">
          <div class="b-pd-nav__cards">
            <div
              v-if="context.specialities"
              class="b-pd-nav__cards-item"
            >
              <div class="b-card-nav">
                <a
                  class="b-card-nav__header"
                  :href="`/${context.town.translit}/vrach/`"
                >
                  <div class="b-card-nav__title">Врачи</div>
                  <div class="b-card-nav__all-count">{{ formatNumber(context.town.drall) }}</div>
                </a>

                <div class="b-card-nav__main">
                  <ul class="b-card-nav__list">
                    <template v-for="card in context.specialities">
                      <li
                        v-if="card.doctors"
                        :key="card.spec__name"
                        class="b-card-nav__list-item"
                      >
                        <a
                          :href="`/${context.town.translit}/${card.spec__translit}/`"
                          class="b-card-nav__item-link"
                        >
                          {{ card.spec__name }}
                        </a>
                        <div class="b-card-nav__item-count">
                          {{ card.doctors }}
                        </div>
                      </li>
                    </template>
                  </ul>
                </div>

                <div class="b-card-nav__footer">
                  <a
                    :href="`/${context.town.translit}/vrach/`"
                    class="b-button b-button_light"
                  >
                    <span class="text-button font-weight-medium">Все врачи</span>
                  </a>
                </div>
              </div>
            </div>

            <div
              v-if="context.lputypes"
              class="b-pd-nav__cards-item"
            >
              <div class="b-card-nav">
                <a
                  class="b-card-nav__header"
                  :href="`/${context.town.translit}/lpu/`"
                >
                  <div class="b-card-nav__title">Клиники</div>
                  <div class="b-card-nav__all-count">{{ formatNumber(context.town.lpuall) }}</div>
                </a>

                <div class="b-card-nav__main">
                  <ul class="b-card-nav__list">
                    <template v-for="card in context.lputypes">
                      <li
                        v-if="card.lpus"
                        :key="card.lputype__name"
                        class="b-card-nav__list-item"
                      >
                        <a
                          :href="`/${context.town.translit}/top/${card.lputype__translit}/`"
                          class="b-card-nav__item-link"
                        >
                          {{ card.lputype__name }}
                        </a>
                        <div class="b-card-nav__item-count">
                          {{ card.lpus }}
                        </div>
                      </li>
                    </template>
                  </ul>
                </div>

                <div class="b-card-nav__footer">
                  <a
                    :href="`/${context.town.translit}/lpu/`"
                    class="b-button b-button_light"
                  >
                    <span class="text-button font-weight-medium">Все клиники</span>
                  </a>
                </div>
              </div>
            </div>

            <div
              v-if="context.services"
              class="b-pd-nav__cards-item"
            >
              <div class="b-card-nav">
                <a
                  class="b-card-nav__header"
                  :href="`/${context.town.translit}/uslugi/`"
                >
                  <div class="b-card-nav__title">Услуги</div>
                  <div class="b-card-nav__all-count">{{ context.town.services_prices }}</div>
                </a>

                <div class="b-card-nav__main">
                  <ul class="b-card-nav__list">
                    <template v-for="card in context.services">
                      <li
                        v-if="card.addresses"
                        :key="card.name_menu || card.name"
                        class="b-card-nav__list-item"
                      >
                        <a
                          :href="card.url"
                          class="b-card-nav__item-link"
                        >
                          {{ card.name_menu || card.name }}
                        </a>
                        <div class="b-card-nav__item-count">
                          {{ card.addresses }}
                        </div>
                      </li>
                    </template>
                  </ul>
                </div>

                <div class="b-card-nav__footer">
                  <a
                    :href="`/${context.town.translit}/uslugi/`"
                    class="b-button b-button_light"
                  >
                    <span class="text-button font-weight-medium">Все услуги</span>
                  </a>
                </div>
              </div>
            </div>

            <div
              v-if="context.diagnostics"
              class="b-pd-nav__cards-item"
            >
              <div class="b-card-nav">
                <a
                  class="b-card-nav__header"
                  :href="`${context.town.translit}/diagnostika/`"
                >
                  <div class="b-card-nav__title">Диагностика</div>
                  <div class="b-card-nav__all-count">{{ formatNumber(context.town.diagnostics_prices) }}</div>
                </a>

                <div class="b-card-nav__main">
                  <ul class="b-card-nav__list">
                    <template v-for="card in context.diagnostics">
                      <li
                        v-if="card.addresses"
                        class="b-card-nav__list-item"
                      >
                        <a
                          :href="card.url"
                          class="b-card-nav__item-link"
                        >
                          {{ card.name_menu || card.name }}
                        </a>
                        <div class="b-card-nav__item-count">
                          {{ card.addresses }}
                        </div>
                      </li>
                    </template>
                  </ul>
                </div>

                <div class="b-card-nav__footer">
                  <a
                    :href="`${context.town.translit}/diagnostika/`"
                    class="b-button b-button_light"
                  >
                    <span class="text-button font-weight-medium">Вся диагностика</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DefaultLayoutMobile>
</template>

<script setup lang="ts">
import type { DjangoContext } from '%/types'
// eslint-disable-next-line import/no-unresolved
import { Head } from '@unhead/vue/components'
import DefaultLayoutMobile from '~/layouts/DefaultLayout/DefaultLayoutMobile.vue'
import TheHeader from '~/deprecated/components/TheHeader/TheHeaderMobile.vue'
import TheSearch from '~/deprecated/components/TheSearch/TheSearch.vue'

const props = defineProps<{
  context: DjangoContext
}>()

const { context } = props

const formatNumber = (number: number) => number.toLocaleString('ru-RU').replace(/,/g, ' ')
</script>

<style scoped lang="scss">
@import 'frontend/styles/variables';

.b-intro {
  line-height: initial;
  background: $ui-kit-bg-gray-0;
  padding: 40px 0;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.03);

  &__header {
    text-align: center;
  }

  &__title {
    font-size: 25px;
    font-weight: 500;
  }

  &__search-container {
    margin-top: 24px;
  }

  &__search-wrapper {
    transform: translateY(50%);
    margin-bottom: 24px;
    position: relative;
  }

  &_padding_compact {
    padding: 20px 0 0;
  }

  &__stat {
    margin-top: 16px;
  }

  &__stat-reviews-item {
    background: $ui-kit-bg-gray-40;
    text-align: center;
    padding: 15px 8px;
    border-radius: 5px;
  }

  &__stat-reviews-item-num {
    font-size: 30px;
    font-weight: 500;
  }

  &__stats-container {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
  }

  &__stat-item {
    flex: 0 0 50%;
    margin: 12px 0;
  }

  &__stat-item-img {
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: top;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 27px auto;

    &_type {
      &_reviews {
        background-image: url('/static/_v1/pd/icons/advantages/review.svg');
      }

      &_appointment {
        background-image: url('/static/_v1/pd/icons/advantages/appointment.svg');
      }

      &_doctor {
        background-image: url('/static/_v1/pd/icons/advantages/doctor.svg');
      }

      &_lpu {
        background-image: url('/static/_v1/pd/icons/advantages/lpu.svg');
      }

      &_users {
        background-image: url('/static/_v1/pd/icons/advantages/group-users.svg');
      }
    }
  }

  &__stat-item-content {
    display: inline-block;
    vertical-align: middle;
    padding-left: 11px;
    padding-right: 24px;
    box-sizing: border-box;
    width: calc(100% - 40px);
  }

  &__stat-item-num {
    font-size: 18px;
    font-weight: 500;
  }
}

.b-pd-nav {
  line-height: initial;
  padding-bottom: 40px;

  &__header {
    text-align: center;
    margin-bottom: 24px;
  }

  &__title {
    margin: 0;
    display: inline-flex;
    align-items: center;
    font-size: 25px;
    font-weight: 400;

    &::before {
      content: '';
      flex: 0 0 auto;
      display: inline-flex;
      width: 43px;
      height: 49px;
      background: url('/static/_v1/pd/icons/fire-red-with-shadow.svg') left center / contain no-repeat;
    }
  }

  &__title-text {
    color: $ui-kit-text-secondary;

    &::before {
      content: '';
    }
  }

  &__title-town-link {
    border-bottom: 2px dashed $ui-kit-text;
    font-weight: 500;
    color: $ui-kit-text;
    text-decoration: none;

    &:hover {
      color: $ui-kit-text;
    }
  }

  &__heading {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    min-height: 36px;
  }

  &__heading-text {
    background-image: url('/static/_v1/pd/icons/fire-red.svg');
    background-position-x: 3px;
    background-repeat: no-repeat;
    color: $ui-kit-text-secondary;
    display: block;
    margin-bottom: 6px;
    padding: 1px 0 0 22px;
    text-transform: uppercase;
    transform: translateX(-6px);
  }

  &__heading-town-link {
    border-bottom: 2px dashed $ui-kit-text;
    color: $ui-kit-text;
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;

    &:hover {
      color: $ui-kit-text;
    }
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px -32px;
  }

  &__cards-item {
    padding: 0 16px;
    margin-bottom: 32px;
    box-sizing: border-box;
    flex: 0 0 auto;
    width: 100%;
  }
}

.b-card-nav {
  line-height: initial;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 5px;
  background-color: $ui-kit-bg-gray-0;
  box-shadow: 0 15px 20px rgba(151, 158, 167, 0.1);
  transition: 0.2s ease-in-out;
  will-change: transform;

  &:hover {
    box-shadow: 0 15px 20px rgba(151, 158, 167, 0.2);
    transform: translateY(-10px);
  }

  &__header {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
  }

  &__title {
    font-size: 18px;
    font-weight: 500;
    line-height: 130%;
    color: $ui-kit-text;
    text-decoration: none;
  }

  &__header:hover &__title {
    text-decoration: underline;
  }

  &__main {
    flex: 1 1 auto;
    padding: 0 24px;
  }

  &__footer {
    padding: 0 24px 24px;
  }

  &__all-count {
    font-size: 18px;
    font-weight: 59;
    line-height: 130%;
    color: $ui-kit-text-secondary;
    text-decoration: none;
  }

  &__list {
    margin: 0;
    padding: 16px 0;
    border-top: 1px solid $ui-kit-bg-gray-60;
  }

  &__list-item {
    display: flex;
    justify-content: space-between;
  }

  &__item-link {
    flex: 1;
    display: block;
    padding: 8px 16px 8px 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 130%;
    color: #5a5c61;
    text-decoration: none;
    transition: 0.2s;

    &:hover {
      color: $ui-kit-primary;
      text-decoration: underline;
    }
  }

  &__item-count {
    padding: 8px 0 8px 16px;
    font-size: 16px;
    line-height: 130%;
    color: $ui-kit-text-secondary;
  }
}

@mixin background-transition-timing {
  transition-property: background-color;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@mixin reactions($hoverBack, $pressedBack, $fontColor) {
  @include background-transition-timing;

  &:hover {
    background-color: $hoverBack;
    color: $fontColor;
  }

  &:active {
    background-color: $pressedBack;
    color: $fontColor;
  }
}

.b-button {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 40px;
  padding: 0 16px;
  border: none;
  border-radius: 4px;
  background-color: $ui-kit-bg-gray-60;
  color: $ui-kit-text;
  white-space: nowrap;
  text-decoration: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  appearance: none;

  &_light {
    background-color: $ui-kit-bg-primary;
    color: $ui-kit-primary;

    @include reactions(#D1E8F4, #C1E0F0, $ui-kit-primary);
  }
}
</style>
