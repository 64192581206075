<template>
  <VScrollYReverseTransition>
    <VBanner
      v-if="isVisible"
      class="draft-reminder"
      :class="{ 'draft-reminder_desktop b-container mb-5': !isMobile }"
      elevation="24"
    >
      <div
        class="d-flex justify-space-between"
        :class="{ 'flex-column': isMobile }"
      >
        <div
          class="d-flex"
          :class="{
            'align-center': !isMobile,
            'pt-2': isMobile,
          }"
        >
          <img
            class="draft-reminder__avatar"
            :class="{ 'draft-reminder__avatar_lpu': !isReviewObjectDoctor }"
            :alt="storageData.reviewObject.name"
            :src="storageData.reviewObject.avatarUrl"
          >
          <div
            class="draft-reminder_text text-body-2"
            :class="{
              'ml-4': isMobile,
              'ml-6': !isMobile,
            }"
          >
            У вас остался незавершённый отзыв о {{ reviewObjectTypeText }}
            <span
              class="text-subtitle-2"
              :class="{ 'text-no-wrap': isReviewObjectDoctor }"
            >
              {{ reviewObjectNameText }}
            </span>
            <br v-if="isMobile">Хотите дописать его и поделиться мнением с пациентами?
          </div>
        </div>
        <div class="d-flex align-center justify-end">
          <VBtn
            class="px-2"
            color="uiKitText"
            variant="text"
            @click="handleClickNotNow"
          >
            Не сейчас
          </VBtn>
          <VBtn
            class="px-2 ml-2"
            :class="{ 'mr-2': !isMobile }"
            color="primary"
            :href="newRateUrl"
            variant="text"
            @click="handleClickGoToReview"
          >
            К отзыву
          </VBtn>
        </div>
      </div>
    </VBanner>
  </VScrollYReverseTransition>
</template>

<script>
import Cookie from 'js-cookie'
import { differenceInHours, differenceInMinutes } from 'date-fns'
import { axiosClient } from '~/deprecated/functions'
import UserStorage from '~/deprecated/modules/UserStorage'
import {
  STORAGE_KEY,
  HOURS_BETWEEN_DISPLAYS,
  MINUTES_BEFORE_FIRST_DISPLAY,
  MAX_NUMBER_OF_DISPLAYS,
  REVIEW_OBJECT_TYPES,
  AJAX_URL_IS_VISIBLE,
  DRAFT_REMINDER_YANDEX_GOALS,
} from '~/deprecated/components/DraftReminder/constants'
import ManageYandexGoals from '~/deprecated/mixins/ManageYandexGoals'

export default {
  name: 'DraftReminder',
  mixins: [
    ManageYandexGoals,
  ],
  data() {
    const { context } = this.$store.state.rootProps

    return {
      isVisible: false,
      storageData: {
        reviewObject: {
          type: '',
          name: '',
          id: 0,
          avatarUrl: '',
        },
        createdAt: 0,
        lastDisplayedAt: 0,
        displaysCount: 0,
      },
      cookieUserkey: Cookie.get('userkey'),
      isMobile: context.flavour === 'mobile',
      yaGoalsList: {
        ...DRAFT_REMINDER_YANDEX_GOALS,
      },
    }
  },
  computed: {
    newRateUrl() {
      const reviewObjectType = REVIEW_OBJECT_TYPES[this.storageData.reviewObject.type]

      if (!reviewObjectType) {
        return ''
      }

      return `/new/rate/${reviewObjectType}/${this.storageData.reviewObject.id}/`
    },
    isReviewObjectDoctor() {
      return this.storageData.reviewObject.type === REVIEW_OBJECT_TYPES.doctor
    },
    reviewObjectTypeText() {
      return this.isReviewObjectDoctor ? 'враче' : 'клинике'
    },
    reviewObjectNameText() {
      return `${this.storageData.reviewObject.name}${this.isReviewObjectDoctor ? '' : '.'}`
    },
  },
  watch: {
    storageData: {
      deep: true,
      handler(newStorageData) {
        try {
          UserStorage.set(STORAGE_KEY, newStorageData)
        } catch {
          UserStorage.remove(STORAGE_KEY)
        }
      },
    },
  },
  mounted() {
    if (!this.cookieUserkey) {
      return
    }

    const storageData = UserStorage.get(STORAGE_KEY)

    if (!storageData || typeof storageData !== 'object' || !storageData.reviewObject) {
      return
    }

    const currentDate = Date.now()
    const { createdAt, lastDisplayedAt, displaysCount } = storageData

    const isTooEarlyToShow = differenceInMinutes(currentDate, createdAt) < MINUTES_BEFORE_FIRST_DISPLAY
            || differenceInHours(currentDate, lastDisplayedAt) < HOURS_BETWEEN_DISPLAYS

    if (isTooEarlyToShow || displaysCount >= MAX_NUMBER_OF_DISPLAYS) {
      return
    }

    this.storageData = storageData

    const userData = {
      userkey: this.cookieUserkey,
      object_type: storageData.reviewObject.type,
      object_id: storageData.reviewObject.id,
    }

    axiosClient.post(AJAX_URL_IS_VISIBLE, userData)
      .then(({ data }) => {
        const { result: isVisible } = data

        if (isVisible) {
          this.isVisible = true

          this.sendYandexGoal('show')
        } else {
          UserStorage.remove(STORAGE_KEY)
        }

        return null
      })
      .catch(() => {
        UserStorage.remove(STORAGE_KEY)
      })
  },
  methods: {
    handleClickNotNow() {
      this.isVisible = false
      this.storageData.displaysCount++
      this.storageData.lastDisplayedAt = Date.now()

      this.sendYandexGoal('notNow')
    },
    handleClickGoToReview() {
      this.sendYandexGoal('goToReview')
    },
  },
}
</script>

<style scoped lang="scss">
@import 'frontend/styles/variables';

.draft-reminder {
  position: fixed;
  bottom: 0;
  z-index: 201;
  background-color: $ui-kit-bg-gray-0;

  &_desktop {
    left: 50%;
    transform: translateX(-50%);

    .draft-reminder_text {
      width: 80%;
    }
  }

  &__avatar {
    width: 60px;
    height: 80px;
    object-fit: contain;
    border-radius: 4px;
  }

  &__avatar_lpu {
    height: 60px;
  }
}
</style>
