<template>
  <VDialog
    :model-value="isVisibleModal"
    :style="{ zIndex: 210 }"
    fullscreen
    persistent
    hide-overlay
    transition="fade-transition"
  >
    <ModalLayout
      close-to
      limit-title
      title="Как сохранить сайт"
      @modal:back="handleCloseModal"
    >
      <div
        class="shortcut-modal"
        :class="{ 'shortcut-modal_center': isVisibleUnknownBrowserInstruction }"
      >
        <div class="pt-4 px-2 pb-12 text-center">
          <template
            v-if="isVisibleUnknownBrowserInstruction"
          >
            <VImg
              width="84"
              height="130"
              class="mx-auto mb-6"
              src="/static/_v1/pd/icons/safari-phone.svg"
              alt="Иконка браузера Safari на телефоне - ПроДокторов"
            />
            <div class="text-subtitle-1 text-text mb-1">
              Скопируйте ссылку и откройте в <b>Safari</b>
            </div>
            <div class="text-body-1 text-text mb-4">
              Там&nbsp;расскажем, как&nbsp;сохранить сайт<br>
              на&nbsp;главном экране
            </div>
            <VBtn
              depressed
              color="uiKitBgPrimary"
              @click="copyToClipboard"
            >
              <span class="text-primary">
                Скопировать ссылку
              </span>
            </VBtn>
          </template>
          <template
            v-else
          >
            <VCard
              v-for="img in imageList"
              :key="img.src"
              elevation="1"
              class="mb-4"
              :class="{ 'pb-4': img.srcOther }"
            >
              <VImg :src="isInstructionSwitched && img.srcOther ? img.srcOther : img.src" />
              <span
                v-if="img.srcOther"
                class="text-body-2 text-primary"
                @click="handleClickSwitchInstruction"
              >{{ isInstructionSwitched ? '&nbsp;' : 'У меня нет такой кнопки' }}</span>
            </VCard>
          </template>
        </div>
      </div>
    </ModalLayout>
  </VDialog>
</template>

<script>
import copy from 'copy-to-clipboard'
import YaGoals from '~/deprecated/modules/YandexGoals'
import { dispatchCustomEventGlobally } from '~/deprecated/functions'
import ModalLayout from '~/deprecated/components/ModalLayout/ModalLayout.vue'
import { SHORTCUT_HOME_SCREEN_YA_GOALS, ID_FOR_DIRECT_OPEN_MODAL } from '~/deprecated/components/ShortcutHomeScreen/constants'
import { EVENTS as SNACKBAR_EVENTS } from '~/deprecated/components/SnackbarNotification/constants'

export default {
  name: 'ShortcutHomeScreenModal',
  components: {
    ModalLayout,
  },
  props: {
    isVisibleModal: {
      type: Boolean,
      default: false,
    },
    userAgentData: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    instanceYaGoals: new YaGoals(),
    isInstructionSwitched: false,
  }),
  computed: {
    isVisibleUnknownBrowserInstruction() {
      return this.userAgentData.isIOS && !this.userAgentData.isKnowThisBrowser
    },
    imageList() {
      if (this.userAgentData.isIOS && this.userAgentData.isSafari) {
        return [
          { src: '/static/_v1/pd/screenshots/shortcut-icon-instruction/ios-safari/1.jpg' },
          { src: '/static/_v1/pd/screenshots/shortcut-icon-instruction/ios-safari/2.jpg' },
          { src: '/static/_v1/pd/screenshots/shortcut-icon-instruction/ios-safari/3.jpg' },
        ]
      }

      if (this.userAgentData.isIOS && (this.userAgentData.isYandex || this.userAgentData.isYandexStart)) {
        return [
          {
            src: '/static/_v1/pd/screenshots/shortcut-icon-instruction/ios-yandex/1.jpg',
            srcOther: '/static/_v1/pd/screenshots/shortcut-icon-instruction/ios-yandex/1-1.jpg',
          },
          { src: '/static/_v1/pd/screenshots/shortcut-icon-instruction/ios-yandex/2.jpg' },
          { src: '/static/_v1/pd/screenshots/shortcut-icon-instruction/ios-yandex/3.jpg' },
          { src: '/static/_v1/pd/screenshots/shortcut-icon-instruction/ios-yandex/4.jpg' },
        ]
      }

      if (this.userAgentData.isIOS && this.userAgentData.isChrome) {
        return [
          { src: '/static/_v1/pd/screenshots/shortcut-icon-instruction/ios-chrome/1.jpg' },
          { src: '/static/_v1/pd/screenshots/shortcut-icon-instruction/ios-chrome/2.jpg' },
          { src: '/static/_v1/pd/screenshots/shortcut-icon-instruction/ios-chrome/3.jpg' },
        ]
      }

      if (this.userAgentData.isAndroid && this.userAgentData.isYandexStart) {
        return [
          { src: '/static/_v1/pd/screenshots/shortcut-icon-instruction/android-yandex-start/1.jpg' },
          { src: '/static/_v1/pd/screenshots/shortcut-icon-instruction/android-yandex-start/2.jpg' },
          { src: '/static/_v1/pd/screenshots/shortcut-icon-instruction/android-yandex-start/3.jpg' },
        ]
      }

      if (this.userAgentData.isAndroid && this.userAgentData.isYandex) {
        return [
          {
            src: '/static/_v1/pd/screenshots/shortcut-icon-instruction/android-yandex/1.jpg',
            srcOther: '/static/_v1/pd/screenshots/shortcut-icon-instruction/android-yandex/1-1.jpg',
          },
          { src: '/static/_v1/pd/screenshots/shortcut-icon-instruction/android-yandex/2.jpg' },
          { src: '/static/_v1/pd/screenshots/shortcut-icon-instruction/android-yandex/3.jpg' },
        ]
      }

      return [
        { src: '/static/_v1/pd/screenshots/shortcut-icon-instruction/android-chrome/1.jpg' },
        { src: '/static/_v1/pd/screenshots/shortcut-icon-instruction/android-chrome/2.jpg' },
        { src: '/static/_v1/pd/screenshots/shortcut-icon-instruction/android-chrome/3.jpg' },
        { src: '/static/_v1/pd/screenshots/shortcut-icon-instruction/android-chrome/4.jpg' },
      ]
    },
  },
  methods: {
    handleCloseModal() {
      this.$emit('modal:close')
    },
    copyToClipboard() {
      this.instanceYaGoals.send({ name: SHORTCUT_HOME_SCREEN_YA_GOALS.clickCopyToClipboard })

      copy(`${window.location.origin}/#${ID_FOR_DIRECT_OPEN_MODAL}`)
      dispatchCustomEventGlobally(SNACKBAR_EVENTS.open, {
        message: 'Ссылка скопирована',
      })
    },
    handleClickSwitchInstruction() {
      this.isInstructionSwitched = true
      this.instanceYaGoals.send({ name: SHORTCUT_HOME_SCREEN_YA_GOALS.clickChangeInstruction })
    },
  },
}
</script>

<style lang="scss" scoped>
.shortcut-modal {
  margin: 0 auto;
  max-width: 420px;

  &_center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
}
</style>
