<template>
  <form
    method="POST"
    class="b-prg b-prg_csrf"
    target="_blank"
    action="/prg/"
  >
    <input
      v-if="isCsrfIncluded"
      type="hidden"
      name="csrfmiddlewaretoken"
      :value="context.csrf_token"
    >

    <input
      type="hidden"
      name="url"
      :value="encodedUrl"
    >
    <button
      v-bind="buttonAttrs"
      type="submit"
    >
      <slot />
    </button>
  </form>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import encodeUrl from '%/utils/encodeUrl'

const props = defineProps<{
  url: string
  buttonAttrs: object
}>()

const store = useStore()
const { context } = store.state.rootProps

const url = props.url.trim()
const encodedUrl = encodeUrl(url)
let isCsrfIncluded: boolean

if (context.flag_store?.pd_always_use_csrf_token_on_prg) {
  isCsrfIncluded = true
} else {
  try {
    const { hostname } = new URL(url)

    isCsrfIncluded = !context.PRG_ALLOWED_HOSTS?.includes(hostname)
  } catch (error) {
    isCsrfIncluded = false
  }
}
</script>
