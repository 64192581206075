import { UAParser } from 'ua-parser-js'
import { BROWSER_NAMES, OS_NAMES } from '~/deprecated/components/ShortcutHomeScreen/constants'

/**
 * Возвращает данные user-агента в удобном формате
 * */

function getUserAgentData() {
  const userAgentParser = new UAParser(navigator.userAgent)

  const { name: nameOS } = userAgentParser.getOS()
  const { name: uaBrowserName } = userAgentParser.getBrowser()

  const customNameBrowser = Object.values(BROWSER_NAMES).find(name => uaBrowserName.includes(name))
  const isYandexStart = customNameBrowser === BROWSER_NAMES.yandex && !navigator.userAgent.includes('YaBrowser')
  const nameBrowser = isYandexStart ? BROWSER_NAMES.yandexStart : customNameBrowser

  return {
    isIOS: nameOS === OS_NAMES.ios,
    isAndroid: nameOS === OS_NAMES.android,
    isSafari: nameBrowser === BROWSER_NAMES.safari,
    isChrome: nameBrowser === BROWSER_NAMES.chrome,
    isYandex: nameBrowser === BROWSER_NAMES.yandex,
    isYandexStart: nameBrowser === BROWSER_NAMES.yandexStart,
    get isKnowThisBrowser() {
      return this.isSafari
        || this.isChrome
        || this.isYandex
        || this.isYandexStart
    },
  }
}

export default getUserAgentData
