import { createDevLog } from '~/deprecated/functions'
import AuthService from '~/deprecated/modules/AuthService'
import {
  isExceededLoginAttempt,
  resetLoginAttemptStorage,
  updateLoginAttemptStorage,
} from '~/deprecated/blocks/auto-login/functions'
import isServer from '~/functions/isServer'
import { useStore } from 'vuex'

export default function autoLogin() {
  const store = useStore()
  const { context } = store.state.rootProps

  if (!isServer() && (context.request.user?.user2?.is_real_patient || !context.request.user?.is_authenticated)) {
    (async () => {
      try {
        const response = await AuthService.pullAuth()

        const {
          authorization_code: authCode,
          uuid,
        } = response.data

        if (authCode) {
          try {
            updateLoginAttemptStorage(uuid)

            if (isExceededLoginAttempt(uuid)) {
              return
            }
          } catch (error) {
            resetLoginAttemptStorage(uuid)

            // Если ошибка не связана с невалидными данными и `JSON`, то пробрасываем её дальше
            if (error.name !== 'SyntaxError' || error.message.indexOf('JSON') === -1) {
              throw error
            }
          }

          await AuthService.login({ authCode })
          window.location.reload()
        }

        // Если юзер залогинен на ПД и является пациентом, но не залогинен на МТ
        if (uuid === null && context.request.user?.user2?.is_real_patient) {
          try {
            const redirectResponse = await AuthService.redirectToMt()
            const { url } = redirectResponse.data

            await AuthService.loginAfterRedirect(url)
          } catch (e) {
            // Если интернета нет, то ошибка возникла из-за его отсутствия (такую ошибку логировать не нужно).
            if (!window.navigator.onLine || !e.status) {
              return
            }

            createDevLog({
              mode: 'error',
              module: 'auto-login',
              method: 'async () -> AuthService.redirectToMt() && AuthService.loginAfterRedirect()',
              description: e.message,
            })
          }
        }
      } catch (e) {
        // Если интернета нет, то ошибка возникла из-за его отсутствия (такую ошибку логировать не нужно).
        if (!window.navigator.onLine || !e.status) {
          return
        }

        createDevLog({
          mode: 'error',
          module: 'auto-login',
          description: `${e.message} | ${JSON.stringify(e.response?.data)}`,
        })
      }
    })()
  }
}
