import YaGoals from '~/deprecated/modules/YandexGoals'
import { createDevLog } from '~/deprecated/functions'

const ManageYandexGoals = {
  data: () => ({
    yaGoalsInstance: null,
    /**
     * yaGoalsList - Объект со списком целей, которые необходимо подключить в
     * каждом компоненте использующий текущий миксин
     *
     * yaGoalsList: {},
     * */
  }),
  mounted() {
    this.yaGoalsInstance = new YaGoals()
  },
  methods: {
    sendYandexGoal(goal) {
      if (!this.yaGoalsInstance) {
        this.$_showYandexGoalError('Отсутствует инстанс "this.yaGoalsInstance"')
        return
      }

      if (!goal) {
        this.$_showYandexGoalError('В метод не передан ключ цели')
        return
      }

      const name = this.yaGoalsList[goal]

      if (!name) {
        this.$_showYandexGoalError('Не обнаружено ни одной цели в списке "this.yaGoalsList"')
        return
      }

      this.yaGoalsInstance.send({ name })
    },
    $_showYandexGoalError(description) {
      createDevLog({
        module: 'mixins/ManageYandexGoals',
        title: 'Error: ',
        message: description,
      })
    },
  },
}

export default ManageYandexGoals
