<template>
  <div
    data-side-menu
    class="app-side-menu"
    :class="{ 'app-side-menu_active': isActive || !isActive && !isTransitionEnd }"
  >
    <div>
      <VNavigationDrawer
        v-model="isActive"
        width="280"
        fixed
        @transitionend="handleTransitionend"
      >
        <VList aria-label="Меню">
          <VListItem
            :href="townSelectUrl"
            data-qa="active_town"
            prepend-icon="ui-icon-map-pin"
            role="option"
          >
            <VListItemTitle
              v-if="town"
              class="text-text"
            >
              {{ town }}
            </VListItemTitle>
            <VListItemTitle
              v-else
              class="text-text-info"
            >
              Город не указан
            </VListItemTitle>
          </VListItem>

          <VDivider
            v-if="isRealPatient || isAnonymous"
            inset
          />

          <VListItem
            v-if="isRealPatient"
            :href="getMedtochkaSectionUrl(medtochkaRoutes.profile)"
            prepend-icon="ui-icon-user-only"
            role="option"
          >
            <VListItemTitle class="text-text">
              Профиль
            </VListItemTitle>
          </VListItem>

          <VListItem
            v-else-if="!isAnonymous"
            :href="personalAreaUrl"
            prepend-icon="ui-icon-doctor"
            role="option"
          >
            <VListItemTitle class="text-text">
              Личный кабинет
            </VListItemTitle>
          </VListItem>

          <template v-else>
            <VListItem
              data-qa="patient_login"
              prepend-icon="ui-icon-user-only"
              role="option"
              @click.prevent="loginRelocation(loginPathMedtochka)"
            >
              <VListItemTitle class="text-text">
                Войти как пациент
              </VListItemTitle>
            </VListItem>
            <VListItem
              data-qa="clinic_login"
              prepend-icon="ui-icon-doctor"
              role="option"
              @click.prevent="loginRelocation(loginPathCabinet)"
            >
              <VListItemTitle class="text-text">
                Войти как врач/клиника
              </VListItemTitle>
            </VListItem>
          </template>

          <template v-if="isRealPatient">
            <VListItem
              v-if="clubData.isInClub"
              role="option"
              @click="handleClickCardInfo"
            >
              <template #prepend>
                <v-img
                  class="mr-8"
                  src="/static/_v1/pd/icons/ui-kit/club/club-check.svg"
                  width="24"
                  height="24"
                  alt="Иконка Галочка Клуба"
                  eager
                />
              </template>
              <div class="py-2">
                <VListItemTitle class="text-text">
                  Клубная карта
                </VListItemTitle>
                <VListItemSubtitle class="text-text-info">
                  до {{ clubData.cardDateEnd }}
                </VListItemSubtitle>
              </div>
            </VListItem>
            <VListItem
              :href="getMedtochkaSectionUrl(medtochkaRoutes.home)"
              prepend-icon="ui-icon-home-medlock"
              role="option"
            >
              <VListItemTitle class="text-text">
                Главная кабинета
              </VListItemTitle>
            </VListItem>
            <VListItem
              :href="getMedtochkaSectionUrl(medtochkaRoutes.appointments)"
              prepend-icon="ui-icon-calendar-date-and-clock"
              role="option"
            >
              <VListItemTitle class="text-text">
                Записи на приём
              </VListItemTitle>
            </VListItem>
            <VListItem
              :href="getMedtochkaSectionUrl(medtochkaRoutes.rates)"
              prepend-icon="ui-icon-reviews"
              role="option"
            >
              <VListItemTitle class="text-text">
                Отзывы
              </VListItemTitle>
            </VListItem>
            <VListItem
              :href="getMedtochkaSectionUrl(medtochkaRoutes.favourites)"
              prepend-icon="ui-icon-heart"
              role="option"
            >
              <VListItemTitle class="text-text">
                Избранное
              </VListItemTitle>
            </VListItem>
            <VListItem
              :href="getMedtochkaSectionUrl(medtochkaRoutes.medcard)"
              prepend-icon="ui-icon-med-book"
              role="option"
            >
              <VListItemTitle>
                Медкарта
              </VListItemTitle>
              <VListItemSubtitle
                v-if="medcardStatusText"
                class="text-error"
              >
                {{ medcardStatusText }}
              </VListItemSubtitle>
            </VListItem>
            <VListItem
              prepend-icon="ui-icon-log-out"
              role="option"
              @click="handleLogout"
            >
              <VListItemTitle class="text-text">
                Выйти
              </VListItemTitle>
              <VListItemSubtitle class="text-text-info">
                на всех устройствах
              </VListItemSubtitle>
            </VListItem>
          </template>

          <VDivider
            v-if="isRealPatient || isAnonymous"
            inset
          />

          <VListItem
            v-if="projects"
            data-qa="other_projects"
            prepend-icon="ui-icon-more-projects"
            role="option"
            @click="openOurProjectsDialog"
          >
            <VListItemTitle class="text-text">
              Наши проекты
            </VListItemTitle>
          </VListItem>
        </VList>
      </VNavigationDrawer>

      <OurProjectsDialog
        v-if="projects"
        :value="isActiveOurProjectsDialog"
        :projects="projects"
        @input="isActiveOurProjectsDialog = $event"
      />
    </div>
  </div>
</template>

<script>
import Cookie from 'js-cookie'
import AuthService from '~/deprecated/modules/AuthService'
import { emitOpenCardInfo } from '~/deprecated/components/ClubCardInfo/functions'
import { EVENTS as DIALOG_EVENTS } from '~/deprecated/components/BaseDialog/constants'
import { EVENTS as SNACKBAR_EVENTS, ERROR as SNACKBAR_ERROR } from '~/deprecated/components/SnackbarNotification/constants'
import OurProjectsDialog from '~/deprecated/components/OurProjectsDialog/OurProjectsDialog.vue'
import ChooseLoginType from '~/deprecated/mixins/ChooseLoginType'
import {
  dispatchCustomEventGlobally,
  getQueryParam,
  axiosClient,
} from '~/deprecated/functions'
import {
  DIALOG_LOGOUT,
  SNACKBAR_TOWN_SELECTED,
  EVENTS as SIDE_MENU_EVENTS,
  MEDCARD_STATUS,
} from './constants'

export default {
  name: 'SideMenu',
  components: {
    OurProjectsDialog,
  },
  mixins: [ChooseLoginType],
  props: {
    town: {
      type: String,
      default: '',
    },
    townSelectUrl: {
      type: String,
      required: true,
    },
    projects: {
      type: Object,
      default: undefined,
    },
    personalAreaUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    const { context } = this.$store.state.rootProps

    return {
      isActive: false,
      isRealPatient: context.request.user?.user2?.is_real_patient,
      isAnonymous: !context.request.user?.is_authenticated,
      medcardStatus: null,
      medtochkaRoutes: {
        home: '/',
        profile: '/profile',
        appointments: '/appointments',
        rates: '/rates',
        favourites: '/favourites',
        medcard: '/medcard',
      },
      isActiveOurProjectsDialog: false,
      clubData: {
        isInClub: context.request.user?.is_in_club,
        cardMtLink: context.request.user?.club_card?.mt_link || '',
        cardDateEnd: context.request.user?.club_card?.dt_end || '',
        cardDateStart: context.request.user?.club_card?.dt_start || '',
        cardNextPrice: context.request.user?.next_club_card_price || null,
        email: context.request.user?.club_card_request?.receipt_email || '',
        discountOnServices: context.service_club_discount_percent || null,
      },
      isTransitionEnd: true,
      isCanRemoveUserKey: false, // Позволяет менеджерам из ПДМобиля повторно оставлять отзывы с одного планшета
    }
  },
  computed: {
    medcardStatusText() {
      switch (this.medcardStatus) {
        case MEDCARD_STATUS.EXPIRED:
          return 'Заблокирована'
        case MEDCARD_STATUS.NEED_CONFIRMATION:
          return 'Подтвердите доступ'
        default:
          return null
      }
    },
  },
  watch: {
    isActive() {
      this.isTransitionEnd = false
    },
  },
  mounted() {
    window.addEventListener('setSecretKeyPdMobile', () => {
      this.isCanRemoveUserKey = true
    })
    window.addEventListener(SIDE_MENU_EVENTS.open, () => {
      this.isActive = !this.isActive
    })

    if (this.isRealPatient) {
      this.setupMedcardStatus()
    }

    const selectedTown = getQueryParam('selectedTown')

    if (selectedTown && document.referrer === window.location.origin + this.townSelectUrl) {
      dispatchCustomEventGlobally(SNACKBAR_EVENTS.open, {
        ...SNACKBAR_TOWN_SELECTED,
        message: SNACKBAR_TOWN_SELECTED.message + this.town,
        handleAction: () => {
          window.location.href = this.townSelectUrl
        },
      })
    }
  },
  methods: {
    async setupMedcardStatus() {
      try {
        const { data: { medcard_status: status } } = await axiosClient.get(`${this.$store.state.rootProps.context.site_mt}/api/me/`, {
          withCredentials: true,
        })

        this.medcardStatus = status
      } catch {}
    },
    openOurProjectsDialog() {
      this.isActiveOurProjectsDialog = true
    },
    getMedtochkaSectionUrl(route) {
      return `${this.$store.state.rootProps.context.site_mt}${route}`
    },
    handleLogout() {
      dispatchCustomEventGlobally(DIALOG_EVENTS.open, {
        ...DIALOG_LOGOUT,
        beforeConfirm: () => this.logout(),
      })
    },
    loginRelocation(url) {
      if (navigator.cookieEnabled && this.isCanRemoveUserKey) {
        Cookie.remove('userkey')
      }

      window.location.assign(url)
    },
    async logout() {
      try {
        await AuthService.logoutPd()
        window.location.assign('/')
      } catch {
        dispatchCustomEventGlobally(SNACKBAR_EVENTS.open, SNACKBAR_ERROR)
      }
    },
    handleClickCardInfo() {
      this.isActive = false
      emitOpenCardInfo()
    },
    handleTransitionend() {
      this.isTransitionEnd = true
    },
  },
}
</script>

<style scoped lang="scss">
:deep(.v-list-item-subtitle) {
  opacity: 1;
}
</style>
