<template>
  <v-toolbar
    class="base-toolbar"
    color="bg-gray-0"
    :class="{ 'base-toolbar_borderless': borderless }"
    flat
  >
    <v-btn
      size="24"
      color="text"
      icon
      @click="$emit('input', false)"
    >
      <v-icon :class="iconClasses" />
    </v-btn>
    <v-toolbar-title
      v-if="title"
      class="text-text"
    >
      {{ title }}
    </v-toolbar-title>
    <v-spacer />
  </v-toolbar>
</template>

<script>
import { ICONS } from './constants'

export default {
  name: 'BaseToolbar',
  props: {
    icon: {
      type: String,
      default: ICONS.close.name,
    },
    title: {
      type: String,
      default: '',
    },
    borderless: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['input'],
  computed: {
    iconClasses() {
      return this.icon === ICONS.close.name ? ICONS.close.classes : ICONS.arrowBack.classes
    },
  },
}
</script>

<style lang="scss">
@import 'frontend/styles/variables';

#app .base-toolbar {
  border-bottom: 1px solid $ui-kit-bg-gray-60;

  &_borderless {
    border-bottom: 0;
  }
}
</style>
